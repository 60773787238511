import {useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Spin, Typography } from "antd";
import { doubleDigitNumber } from "../../Common/commondata/commonData";
import { detectWebView, generateFullNameFromObj, generateInitialsFromObj } from "../../Common/utils/universalFuntions";
import styles from "../style/NavbarForRtd.module.css";
import { useDispatch, useSelector } from "react-redux";
import { offerTableHandle } from "../../OfferTable/state/actions";
import { useMediaQuery } from "react-responsive";
import dotEnv from "dotenv";
dotEnv.config();
const { Text } = Typography;
const apiURL = process.env.REACT_APP_WEB_BASE_URL;

const NavbarForRtd = ({ currentlySelectedData }) => {
    const { URLofferTableJSON, currentSellerAgentData, offerTablePropertyId,offerTableAuthKey } = useSelector((state) => state.offerTable);
    const isSmallScreen = useMediaQuery({maxWidth: 550});
    const isMediumScreen = useMediaQuery({minWidth: 551, maxWidth: 850});
    const { transparentDivData } = useSelector((state) => state.contractStore);
    let getOfferNumber = (number) => {
        if ((number + "").length > 1) {
          return number;
        } else {
          return doubleDigitNumber[number];
        }
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const urlLink = window.location.href.split("/");
    const handleBack = async ()=>{
        // const isInWebView = detectWebView();
        if(URLofferTableJSON?.urlData?.sharedIndividualOffer){
            history.push(`/success?client=true`);
            return;
        }
        if (urlLink?.length >= 3) {
          history.push(`/offer/${urlLink[urlLink.length - 3]}`);
          if (offerTablePropertyId && offerTableAuthKey) {
            dispatch(offerTableHandle.getOfferTable(offerTableAuthKey, offerTablePropertyId, "PRICE", "DEC"));
        }
        } else {
          window.location.href=`${apiURL}`
        }
      
        // if (isInWebView) {
        //     let redirectHome = async () => {
        //         await window?.ReactNativeWebView?.postMessage("GO_BACK");
        //     };
        //     redirectHome();
        // }else {
         
        // }
        return;
    }
    const agentInitials = generateInitialsFromObj(currentSellerAgentData);

    return (
        <>
          {transparentDivData?.loadingStatus ? (
            <div style={{ position: "fixed", zIndex: "1000", width: "100%", height: "100vh", backgroundColor: "black", opacity: "0.7", display: "flex", gap: "20px", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Spin size="large" />
            </div>
          ) : null}
          <div className={styles.preRTDNavbarContainer}>
            <div className={styles.outerFlexRow}>
              <div className={styles.columnFirst}>
                <div className={styles.backBtnBox} onClick={handleBack}>
                  <ArrowLeftOutlined style={{ fontSize: isSmallScreen ? "14px" : isMediumScreen ? "18px" : "22px" }} />
                  <div style={{ fontSize: isSmallScreen ? "12px" : isMediumScreen ? "18px" : "20px" }}>Back</div>
                </div>
              </div>
              <div className={styles.columnSecond}>
                <div className={styles.navbar}>{currentlySelectedData?.offerNumber ? `Offer-${getOfferNumber(currentlySelectedData?.offerNumber)}` : ""}</div>
              </div>
              <div className={styles.columnThird}>
                <div className={styles.outerContainer}>
                  <Text ellipsis style={{ fontSize: isSmallScreen ? "12px" : isMediumScreen ? "18px" : "20px", color: "white", fontWeight: isSmallScreen ? 400 : 500 }}>
                    {generateFullNameFromObj(currentSellerAgentData)}
                  </Text>
                  <Avatar key="5" src={currentSellerAgentData?.profileImg?.original} size={isSmallScreen ? "small" : isMediumScreen ? 35 : "large"} style={{ margin: "auto", color: "#085190", border: "1px solid #085190", fontSize: "18px" }}>
                    {}
                    {!currentSellerAgentData?.profileImg?.original && agentInitials ? agentInitials : <UserOutlined style={{ fontSize: isSmallScreen ? "16px" : isMediumScreen ? "20px" : "24px" }} />}
                  </Avatar>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}
export default NavbarForRtd;
