import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const getCalendarDetailsRequest = (urlData, timestamp, responseType, allAgents) => {
  const agentIds = []
  allAgents?.forEach((element)=>{
    if(element.selected){
      agentIds.push(element._id)
    }
  })
  return axios.get(`${apiURL}/calendar`, {
    params: {
      ...(urlData?.["offerId"] && { offerId: urlData["offerId"] }),
      ...(urlData?.["openFor"] && { openFor: urlData["openFor"] }),
      ...(agentIds?.length && {agentIds: JSON.stringify(agentIds)}),
      timestamp,
      responseType,
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${urlData["key"]}`,
    },
  });
};

const editCustomTaskRequest = ({taskId, payloadData, urlData}) => {
  const payload = {
    ...payloadData
  };

  return axios.put(`${apiURL}/tasks/${taskId}`, payload, {
    headers: {
      Accept: "application/json",
      Authorization: `bearer ${urlData["key"]}`,
    },
  });
};

// calendar load data for persist
const getCalendarDataRequest = (id) => {
  const config = {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  };
  return axios(`${apiURL}/url/${id}`, config);
};

const getDelegateDetailsRequest = (query, urlData) => {
  // Base URL
  let url = `${apiURL}/delegatesDetail?delegateValue=${query}`;
  
  return axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${urlData["key"]}`,
    },
  });
};

const getRemindersListRequest = (dataObject, year) => {
  return axios.get(
    `${apiURL}/reminder?responseType=YEAR&timestamp=${Date.now()}&year=${year}`,
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${dataObject["key"]}`,
      },
    }
  );
};

const getScheduleListRequest = ({dataObject, year, responseType, source}) => {
  let urlString = `${apiURL}/schedules?responseType=${responseType}&year=${year}`;
  if (source === 'TRANSACTION_CALENDAR') {
    urlString = `${apiURL}/schedules?offerId=${dataObject?.offerId}`
  }
  return axios.get(urlString,
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${dataObject["key"]}`,
      },
    }
  );
};

const shareCalendarToClientsRequest = ({payloadData, userAuthKey}) => {
  return axios.post(`${apiURL}/shareCalendarToClients`,{
    offerId: payloadData?.offerId,
    openFor: payloadData?.openFor,
    clientArray: payloadData?.clientArray,
    ...(payloadData?.emailSignatureId && {emailSignatureId: payloadData?.emailSignatureId}),
    ...(payloadData?.emailMessage && {emailMessage: payloadData?.emailMessage}),
    ...(payloadData?.readOnlyEmailsArray && { readOnlyEmailsArray: payloadData?.readOnlyEmailsArray }),

  },
  {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      authorization: `bearer ${userAuthKey}`,
    }
  })
}

export const calendarDataApi = {
  getCalendarDetailsRequest,
  getCalendarDataRequest,
  getRemindersListRequest,
  getScheduleListRequest,
  shareCalendarToClientsRequest,
  editCustomTaskRequest,
  getDelegateDetailsRequest
};
