import { message, Modal } from "antd";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import RecipientList from "../components/RecipientList";
import styles from "../style/SendEmailModal.module.css";
export const processNestedDataforReadOnly = (data, selectedPersonsState) => {
  let results = [];

  const isMongoDBObjectID = (id) => /^[a-f\d]{24}$/i.test(id);

  if (data && data.length) {
    data.forEach((item) => {
      if (!item?.isCorp && item?.isReadOnly) {
        if (item.personId?._id && isMongoDBObjectID(item.personId._id)) {
          // Only send personId if it's a valid MongoDB ObjectID
          results.push({ personId: item.personId._id });
        } else {
          results.push({
            ...(item.personId.fullName && { fullName: item.personId?.fullName }),
            ...(item.personId.email && { email: item.personId?.email }),
          });
        }
      } else if (item?.isCorp && item?.signers?.length > 0) {
        // Handle the case for corps
        item.signers.forEach((signer) => {
          if (signer.isReadOnly) {
            if (signer.personId?._id && isMongoDBObjectID(signer.personId?._id)) {
              results.push({ personId: signer.personId._id });
            } else {
              results.push({
                ...(signer.personId?.email && { email: signer.personId.email }),
              });
            }
          }
        });
      }
    });
  }
  if (Array.isArray(selectedPersonsState?.titleRepsData) && selectedPersonsState.titleRepsData.length) {
    selectedPersonsState.titleRepsData.forEach((item) => {
      results.push({
        ...(item.fullName && { fullName: item?.fullName }),
        ...(item.email && { email: item?.email }),
        openFor: "TITLE_COMPANY",
      });
    });
  }
  if (Array.isArray(selectedPersonsState?.affiliatesData) && selectedPersonsState.affiliatesData.length) {
    selectedPersonsState.affiliatesData.forEach((item) => {
      results.push({
        ...(item.fullName && { fullName: item?.fullName }),
        ...(item.email && { email: item?.email }),
        openFor: "TITLE_COMPANY",
      });
    });
  }

  return results;
};

// not in use for now later we can use this
export const confirmModalHandler = ({ params, agentType, allBuyerAgentData, allSellerAgentData, userAuthKey, dispatch, source }) => {
  const modal = Modal.confirm({
    title: (
      <div className={styles.confirmTitle} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span>
          Are you sure you want to send the selected document{params?.propertyDocsArray?.length > 1 ? "s" : ""} to the following agent{(["SELLING_RTD", "CONTRACT_NAV_SELLER_AGENT"].includes(source) && allBuyerAgentData.length > 1) || (["CONTRACT_NAV_BUYER_AGENT"].includes(source) && allSellerAgentData.length > 1) ? "s" : ""}?
        </span>
        <span
          style={{
            position: "absolute",
            top: "2px",
            right: "10px",
            cursor: "pointer",
            fontSize: "25px",
            color: "grey",
            borderRadius: "50%",
            padding: "5px",
          }}
          onClick={() => modal.destroy()}
        >
          x
        </span>
      </div>
    ),
    okText: "Yes",
    cancelText: "No",
    width: 700,
    icon: <ExclamationCircleOutlined />,
    content: <div style={{ marginTop: "5px", maxWidth: "700px", overflowX: "auto", maxHeight: "450px", overflowY: "auto", whiteSpace: "nowrap" }}>{agentType === "BUYER_AGENT" ? <RecipientList data={allBuyerAgentData} /> : <RecipientList data={allSellerAgentData} />}</div>,
    onOk() {
      dispatch(contractStoreHandle.SendMultipleDoc({ ...params, sendToAgent: agentType }, userAuthKey, true));
    },
    onCancel() {
      if (!params.clientArray.length && !params.readOnlyEmailsArray?.length) {
        message.error("Please add at least one recipient.");
      } else {
        dispatch(contractStoreHandle.SendMultipleDoc(params, userAuthKey, false));
      }
    },
  });
};

export const processSelectedAgentsData = (agentsData) => {
  if (!Array.isArray(agentsData) || !agentsData.length) return [];
  const agents = agentsData.map((agent) => ({
    fullName: agent?.fullName || "",
    email: agent?.email,
    ...(agent?.key && { key: agent?.key }),
    ...(agent?.personId && { personId: agent?.personId }),
  }));
  return agents;
};
