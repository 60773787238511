import { Avatar, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TitleCompanyHandle } from '../state/action';
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Loading } from '../../Common';
const TitleCreated = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1);
  const { gettitleCompanyData, gettitleCompanyLoading } = useSelector((state) => state.titleCompanyDetails)

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const titleCompanyVerificationColumns = [
    {
      title: 'TITLE COMPANY CREATED',
      dataIndex: 'titleType',
      key: 'titleType',
      render: (text) => (
        <div>
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: 'CREATED BY',
      dataIndex: 'nameemail',
      key: 'nameemail',
      width: '16%',
      render: (text) => (
        <div style={{ display: "flex" }}>
          <div>{text?.officeLogo ? <Avatar shape="circle" size={32} src={text?.officeLogo} /> : ""}</div>
          <div style={{ marginLeft: "10px" }}>
            <div>{text?.fullName}</div>
            <div style={{ fontSize: "11px", color: "#787474" }}>
              {text?.email ? text.email : ""}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '12%',
      render: (isVerified) => <div>
        {
          isVerified ? <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <CheckCircleOutlined style={{ color: "#4bd33a", fontSize: "18px" }} />
            Verified
          </div> : <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <CloseCircleOutlined style={{ color: "#e73939", fontSize: "18px" }} />
            Not Verified
          </div>
        }
      </div>,
    },
    {
      title: 'TITLE COMPANY',
      dataIndex: 'company',
      key: 'company',
      width: '23%',
      render: (text) => (
        <div>
          <div style={{ fontSize: "13px", color: "black" }}>{text?.name}</div>
          <div style={{ fontSize: "11px", color: "#787474" }}>
            {text?.address ? `Address:${text?.address}` : ""}
          </div>
        </div>
      ),
    },
    {
      title: 'TITLE OFFICE',
      dataIndex: 'office',
      key: 'office',
      width: '23%',
      render: (text) => (
        <div>
          <div style={{ fontSize: "13px", color: "black" }}>{text?.name}</div>
          <div style={{ fontSize: "11px", color: "#787474" }}>
            {text?.address ? `Address:${text?.address}` : ""}
          </div>
        </div>
      ),
    },
    {
      title: "UPDATE",
      dataIndex: "update",
      key: "update",
      render: (isVerified, ele) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isVerified ? (
            <CheckCircleOutlined
              style={{ color: "#4bd33a", fontSize: "18px" }}
            />
          ) : (
            <button
              style={{ cursor: "pointer" }}
            >
              Mark Verified
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    dispatch(TitleCompanyHandle.getTitleCompanyTypes({ titleCompanyMenu: "verifyTitleCompany", currentPage: 1, pageSize: 20 }))
  }, [currentPage])

  return (
    <>
      {gettitleCompanyLoading ? <Loading /> :
        <Table
          columns={titleCompanyVerificationColumns}
          scroll={{ y: "62vh" }}
          dataSource={gettitleCompanyData}
          bordered
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: 20,
            // total: gettitleCompanyTotalRecords,
            showSizeChanger: false,
            // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          onChange={handleTableChange}
        />}
    </>
  );
}
export { TitleCreated }
