import { message } from "antd";
import { TitleCompanyApi } from "../utils/api";
import { TitleCompanyConstants } from "./action-types";
// import { TitleCompanyConstants } from "./action-types";

const TitleCompanyLoading = () => {
    return {
      type: TitleCompanyConstants.CREATE_TITLECOMPANY_LOADING,
    };
  };
  const TitleCompanyError = (error) => {
    return {
      type: TitleCompanyConstants.CREATE_TITLECOMPANY_FAILURE,
      titleCompanyError: error,
    };
  };
  
  const TitleCompanySuccess = (response) => {
    return {
      type:TitleCompanyConstants.CREATE_TITLECOMPANY_SUCCESS,
      titleCompanyData: response.data.info,
    };
  };
  
  const createTitleCompany = (payload) => (dispatch) => {
    dispatch(TitleCompanyLoading());
    return TitleCompanyApi
      .createTitleCompanyRequest(payload)
      .then((response) => {
        dispatch(TitleCompanySuccess(response));
        message.success("Created Successfully!", 2);
     })
      .catch((error) => {
        message.error(error.response.data.message);
        dispatch(TitleCompanyError(error));
      });
  };

const getTitleCompanyTypes = ({titleCompanyMenu,currentPage, pageSize}) => {
  return (dispatch) => {
    dispatch(getTitleCompanyTypeLoading());
    TitleCompanyApi
      .gettitleCompanyRequest({titleCompanyMenu,currentPage, pageSize})
      .then((response) => {
        dispatch(getTitleCompanyTypeSuccess(response));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(getTitleCompanyTypeError(error.response.data));
        }
      });
  };
};


const getTitleCompanyTypeLoading = (response) => {
  return {
    type: TitleCompanyConstants.GET_TITLECOMPANY_LOADING,
    TitleCompanyLoading: true,
    TitleCompanyError: false,
  };
};

const getTitleCompanyTypeSuccess = (response) => {
return {
    type: TitleCompanyConstants.GET_TITLECOMPANY_SUCCESS,
    TitleCompanyLoading: false,
    TitleCompanyError: false,
    TitleCompanydata: response?.data?.info?.data,
    TitleCompanyTotalRecords : response?.data?.info?.totalRecords
  };
};

const getTitleCompanyTypeError = (response) => {
  return {
    type: TitleCompanyConstants.GET_TITLECOMPANY_ERROR,
    TitleCompanyLoading: false,
    TitleCompanyError: true,
  };
};

const updateClosuresSalesRep = (payloadData) => (dispatch) =>{
  const {currentPage, pageSize, ...remainingData } = payloadData || {}
  try {
    TitleCompanyApi
      .updateClosuresSalesRepRequest(remainingData)
      .then((response) => {
        message.success(response?.data?.info?.message || "Updated successfully!");
        dispatch(getTitleCompanyTypes({titleCompanyMenu: "createTitleCompany" ,currentPage , pageSize}))
      })
      .catch((err) => {
        message.error("Error While Creating the Data!")
      })
  } catch (err) {
    console.log("errrr",err)
    throw err
  }
}

  export const TitleCompanyHandle = {
    createTitleCompany,
    getTitleCompanyTypes,
    updateClosuresSalesRep
  };
