import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const getOfficeTypes = (source,query) => {
  if(!query) {
    query={page: 1}
  }
  query.limit = 20;
 let accessTokan = localStorage.getItem("auth");
  if (source === "createFirm") {
    return axios.get(
      `${apiURL}/office`,
      {
        params:{
          ...query
          },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  } else if(source === "verifyCreatedFirm"){
    return axios.get(`${apiURL}/office`, {
      params:{
      ...query
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  } else {
    return axios.get(`${apiURL}/office`,{
      params:{
        ...query
      },
     headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
};
}
const getAllPersonsApi = (query) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.get(`${apiURL}/getAllPersons?onlyOffices=${true}`,
  {
    params: {
      ...query,
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const updateOfficeType = (officeId, isVerified, key) => {
 let accessTokan = localStorage.getItem("auth");
  if(key === "verify"){
    return axios.put(
      `${apiURL}/office/${officeId}?verify=${true}`,
      {
        isVerified,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  }
  else if(key === "updateVerify"){
    return axios.put(
      `${apiURL}/office/${officeId}?updateVerify=${true}`,
      {
        isVerified,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  }
};

const updatePerson = (payload) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.put(
    `${apiURL}/person/profileUpdate`,
    {
      ...payload,
    },
    {
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const FirmFilterRequest = ( status,onlyOffices) => {
    let accessTokan = localStorage.getItem("auth");
    let queryParams = status ==='VERIFIED' ? `?isVerified=${true}&onlyOffices=${onlyOffices}` : `?isVerified=${false}&onlyOffices=${onlyOffices}`;
    return axios.get(
      `${apiURL}/getAllPersons${queryParams}`,
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
};



export const officeTypeApi = {
  getOfficeTypes,
  updateOfficeType,
  getAllPersonsApi,
  updatePerson,
  FirmFilterRequest,
};
