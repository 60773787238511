import { contractStoreConstants } from "./action-types";
import { submitDataApi } from "../utils/api";
import { contractDataHandle } from "../../CreateOffer/state/actions";
import dotEnv from "dotenv";
import { message } from "antd";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { arrangeClientDataInTD, getcombinedClientsAndAgents } from "../../Common/utils/universalFuntions";
dotEnv.config();
// const apiURL = process.env.REACT_APP_ADMIN_BASE_URL;

/**
 * To update Navbar title action
 * @param {string}
 */

const resetSubmitSuccess = (data) => {
  return {
    type: contractStoreConstants.RESET_FORM,
    submitsuccess: data,
  };
};

// Auto save Contract
const autoSaveContractError = (error) => {
  return {
    type: contractStoreConstants.AUTO_SAVE_CONTRACT_FAILURE,
    autoSaveContractError: error,
    autoSaveContractLoading: false,
    autoSaveContractStaus: false,
  };
};

const autoSaveContractSuccess = (response, templateInfo) => {
  if (templateInfo?.createTemplate && (response?.data?.message === "Success" || response?.data?.type === "Success")) {
    message.success("Template Created Successfully.");
  }
  return {
    type: contractStoreConstants.AUTO_SAVE_CONTRACT_SUCCESS,
    autoSaveContractsuccess: response.data.info,
    autoSaveContractLoading: false,
    autoSaveContractStaus: false,
  };
};
const autoSaveContractStatusPersistonNavbar = () => {
  return {
    type: contractStoreConstants.AUTO_SAVE_CONTRACT_STATUS,
    autoSaveContractStaus: true,
  };
};

const autoSaveContract = ({currentUrlId, contractAuthKey, signatureBlock, valueObj, templateInfo, netSheetUpdateType, goBack}) => {
  return (dispatch, getState) => {
    if (!getState().contractStore.autoSaveContractStaus) {
      //for navbar
      dispatch(autoSaveContractStatusPersistonNavbar());
    }
      let rootDocData = getState().createOffer?.rootDocData;
      let contractAuthToken = getState().createOffer.contractAuthKey;
      submitDataApi
        .autoSaveContractRequest(currentUrlId, contractAuthKey||contractAuthToken, rootDocData, signatureBlock, valueObj, templateInfo, netSheetUpdateType)
        .then((response) => {
          goBack && goBack();
          dispatch(autoSaveContractSuccess(response, templateInfo));
          // console.log("autoSave Response", response.data.info);
          if (!templateInfo?.createTemplate) {
            dispatch(contractDataHandle.setRootLevelDocDataFun(response.data.info));
            if (!rootDocData?.documentId) {
              dispatch(contractDataHandle.getPersistDeatailsDataOnly(response.data.info?.documentId, contractAuthToken));
            } 
          }
        })
        .catch((error) => {
          message.error(error?.response?.data?.message || "We encountered an issue while saving document.Please try again later!" );
          dispatch(autoSaveContractError(error));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          goBack && goBack();
        });
    }
};

// Validate Contract
const validateFormError = (error) => {
  return {
    type: contractStoreConstants.VALIDATE_FORM_FAILURE,
    validateError: error,
    validateLoading: false,
  };
};

const validateFormSuccess = (response) => {
  return {
    type: contractStoreConstants.VALIDATE_FORM_SUCCESS,
    validatesuccess: response.data.info,
    validateLoading: false,
  };
};

const validateForm = () => {
  return (dispatch, getState) => {
    let contractId = getState().createOffer?.rootDocData?.contractId?._id||getState().createOffer?.rootDocData?.contractId;
      let accessTokan = getState()?.createOffer?.contractAuthKey;
      let urlId = getState().createOffer.currentUrlId;
      let rootDocData=getState().createOffer?.rootDocData
    submitDataApi
      .validateFormRequest(urlId, accessTokan,contractId,rootDocData)
      .then((response) => {
        dispatch(validateFormSuccess(response));
      })
      .catch((error) => {
        dispatch(validateFormError(error));
      });
  };
};

const signatureLoading = (value) => {
  return {
    type: contractStoreConstants.RESET_SIGNATURE_LOADING,
    resetSignatureLoading: value,
  };
};

const resetSignatures = (documentId, roleType, accessTokan) => {
  return (dispatch, getState) => {
    dispatch(signatureLoading(true));
    submitDataApi
      .resetSingatureApi(documentId, roleType, accessTokan)
      .then((response) => {
        message.success(response.data.info);
        // dispatch(signatureLoading(false));
        setTimeout(() => {
          window?.location?.reload();
        }, 2000);
      })
      .catch((error) => {
        dispatch(signatureLoading(false));
        message.error(error?.response?.data?.message || "We encountered an issue while reseting signature.Please try again later!!" );
      });
  };
};
const copyAsDraftOfferFun = (payloadData) => {
  return (dispatch, getState) => {
    dispatch(setTransparentDivLoading({
      loadingStatus: true,
      message:"Saving Contract..."
    }));
    let accessTokan = getState()?.createOffer?.contractAuthKey;
    submitDataApi
      .copyAsDraftOfferApi(payloadData, accessTokan)
      .then((response) => {
        message.success(response.data.info?.message || "Offer has been copied successfully and will be available as a draft offer");
        dispatch(setTransparentDivLoading({
          loadingStatus: false,
          message:""
        }));
      })
      .catch((error) => {
        dispatch(setTransparentDivLoading({
          loadingStatus: false,
          message:""
        }));
        message.error(error?.response?.data?.message || "We encountered an issue.Please try again later!");
      });
  };
};

const invalidateDocument = (documentId, accessTokan) => {
  return (dispatch, getState) => {
    dispatch(signatureLoading(true));
    submitDataApi
      .invalidateDocumentApi(documentId, accessTokan)
      .then((response) => {
        message.success(response.data.info);
        dispatch(signatureLoading(false));
        setTimeout(() => {
          window?.location?.reload();
        }, 2000);
      })
      .catch((error) => {
        dispatch(signatureLoading(false));
        message.error(error?.response?.data?.message || "We encountered an issue.Please try again later!");
      });
  };
};

const addClausesError = () => {
  return {
    type: contractStoreConstants.GET_ADD_CLAUSES_ERROR,
  };
};
const addClausesLoading = () => {
  return {
    type: contractStoreConstants.GET_ADD_CLAUSES_LOADING,
  };
};

const addClausesSuccess = (response) => {
  return {
    type: contractStoreConstants.GET_ADD_CLAUSES_SUCCESS,
    addClausesData: response.data.info,
  };
};

const getAddClausesData = (accessTokan) => {
  return (dispatch, getState) => {
    dispatch(addClausesLoading());
    submitDataApi
      .getAddClausesDataRequest(accessTokan)
      .then((response) => {
        dispatch(addClausesSuccess(response));
      })
      .catch((error) => {
        dispatch(addClausesError(error));
      });
  };
};

const addDocumentsError = (error) => {
  return {
    type: contractStoreConstants.GET_ADD_DOCUMENTS_ERROR,
  };
};
const addDocumentsLoading = () => {
  return {
    type: contractStoreConstants.GET_ADD_DOCUMENTS_LOADING,
  };
};

const addDocumentsSuccess = (response) => {
  return {
    type: contractStoreConstants.GET_ADD_DOCUMENTS_SUCCESS,
    addDocumentsData: response.data.info,
  };
};

const getAddDocumentsData = (accessTokan) => {
  return (dispatch, getState) => {
    dispatch(addDocumentsLoading());
    submitDataApi
      .getAddDocumentsDataRequest(accessTokan)
      .then((response) => {
        dispatch(addDocumentsSuccess(response));
      })
      .catch((error) => {
        dispatch(addDocumentsError(error));
      });
  };
};

const getTransactionLaneError = (error) => {
  return {
    type: contractStoreConstants.GET_TRANSACTION_LANE_FAILURE,
    getTransactionLaneError: error,
    getTransactionLaneLoading: false,
  };
};

const getTranscactionLaneSucess = (response) => {
  return {
    type: contractStoreConstants.GET_TRANSACTION_LANE_SUCCESS,
    getTransactionLaneData: response.data.info,
    getTransactionLaneLoading: false,
  };
};

const getTransactionLaneMethod = (id, role, accessToken) => {
  return (dispatch, getState) => {
    submitDataApi
      .transactionLaneApi(id, role, accessToken)
      .then((response) => {
        dispatch(getTranscactionLaneSucess(response));
      })
      .catch((error) => {
        dispatch(getTransactionLaneError(error));
      });
  };
};

const getTransactionReportError = (error) => {
  return {
    type: contractStoreConstants.GET_TRANSACTION_REPORT_ERROR,
    getTransactionReportError: error,
    getTransactionReportLoading: false,
  };
};

const getTransactionReportSuccess = (response) => {
  return {
    type: contractStoreConstants.GET_TRANSACTION_REPORT_SUCCESS,
    getTransactionReportData: response.data.info,
    getTransactionReportLoading: false,
  };
};

const getTransactionReportMethod = ({offerId, accessToken, contractType, td, urlId}) => {
  return (dispatch, getState) => {
    submitDataApi
      .transactionReportApi({offerId, accessToken, contractType})
      .then((response) => {
        if (contractType === 'TO') {
          // For TO to get populated data of buyers and seller like TR form adding extra logic here.
          let getTransactionReportData = response.data.info;
          const clientData = getcombinedClientsAndAgents({allContractDocuments: getTransactionReportData?.documentsData});
          if (clientData?.combinedBuyerIds?.length) {
            td = {
              ...td,
              ...arrangeClientDataInTD(clientData.combinedBuyerIds, 'Buyer', td),
            }
          }
          if (clientData?.combinedSellerIds?.length) {
            td = {
              ...td,
            ...arrangeClientDataInTD(clientData.combinedSellerIds, 'Seller', td),
            }
          }          
          localStorage.setItem(urlId, JSON.stringify(td||{}));
        }
        dispatch(getTransactionReportSuccess(response));
      })
      .catch((error) => {
        dispatch(getTransactionReportError(error));
      });
  };
};

const getBuyersError = (error) => {
  return {
    type: contractStoreConstants.GET_BUYERS_ERROR,
    getBuyersError: error,
    getBuyersLoading: false,
  };
};

const getBuyersSucess = (responses, party) => {
  return {
    type: contractStoreConstants.GET_BUYERS_SUCCESS,
    getBuyersData: responses.map((response) => response.data.info),
    getBuyersLoading: false,
    party,
  };
};

const getClientDetailsFun = (clients, party, accessToken) => {
  return (dispatch, getState) => {
    submitDataApi
      .getClientDetailsApi(clients, accessToken)
      .then((response) => {
        dispatch(getBuyersSucess(response, party));
      })
      .catch((error) => {
        dispatch(getBuyersError(error));
      });
  };
};
const reSetvalidateForm = () => {
  return {
    type: contractStoreConstants.RESET_VALIDATION_FORM,
  };
};

const convertPdfFun = async (response, rootDocData = {}) => {
  try {
    const blob =  await new Blob([response?.data], { type: 'application/pdf' });
    const fileUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = fileUrl;
    const contractType = rootDocData?.contractType === "FRASCONA_BDB" ? "BDB" : rootDocData?.contractType === "FRASCONA_ERTS" ? "ERTS" : rootDocData?.contractType || "";
    a.download = `RE-Sure_${contractType}_${Date.now()}.pdf`;
    a.click();
    URL.revokeObjectURL(fileUrl);
    message.success("PDF Downloaded Successfully!");
  } catch (error) {
    // console.error("Error converting or downloading PDF:", error);
    message.error("Error downloading PDF. Please try again.");
  }
};

const setTransparentDivLoading = (data) => {
  return {
    type: contractStoreConstants.SET_TRANSPARENT_DIV_LOADING,
    transparentDivData: data,
  };
};
const downloadPdfFun = () => {
  return async (dispatch, getState) => {
    try {
      const rootDocData = getState().createOffer?.rootDocData;
      const urlId = getState().createOffer?.currentUrlId;
      const accessToken = getState().createOffer?.contractAuthKey;
      dispatch(setTransparentDivLoading({
        loadingStatus: true,
        message:"Downloading PDF..."
      }));
      submitDataApi
        .downloadPdfApi(accessToken, urlId, rootDocData)
        .then((response) => {
          dispatch(setTransparentDivLoading({
            loadingStatus: false,
            message:""
          }));
          convertPdfFun(response, rootDocData);
        })
        .catch((error) => {
          dispatch(setTransparentDivLoading({
            loadingStatus: false,
            message:""
          }));
          const errorMessage = error?.response?.data?.message || "The document is currently unavailable for export. Please try again, or contact support if the issue continues.";
          message.error(errorMessage);
        });
    } catch (error) {
      console.error("Error in downloadPdfFun:", error);
    }
  };
};



const deleteTitleCompanyData=(id,contractAuthKey)=>{
  return(dispatch,getState) => {
    // dispatch(deleteTitleCompanyDataLoading())
    submitDataApi.deleteTitleCompanyData(id,contractAuthKey)
    .then((response) => {
      message.success("Deleted Successfully!")
      let urlId = getState()?.createOffer?.rootDocData?.urlId;
      if(urlId){
       let storedData =  JSON.parse(localStorage.getItem(urlId))
        storedData = {
          ...storedData,
          offerId: "",
          TitleOfficeId: "",
          SalesRep: "",
          SalesRepId: "",
          SalesClosingRep: "",
          SalesClosingRepId:"",
          TitleOfficeAddress: "",
          TitleOfficePhoneNo: "",
          TitleOfficeFaxNo: "",
          TitleOfficeName: "",
          TitleCompanyName: "",
          TitleCompanyId: "",
          TitleCompanyLogo: "",
          TitleOfficeEmail:""
      };
      localStorage.setItem(urlId, JSON.stringify(storedData));
      dispatch(autoSaveContract({currentUrlId:urlId, contractAuthKey}))
      }
     })
    .catch((err) => {
      message.error("Error while Clearing the TitleCompany Data!")
    })
  }
}

const SendMultipleDoc = (data, contractAuthKey, agents) => (dispatch) => {
  dispatch(setTransparentDivLoading({
    loadingStatus: true,
    message:""
  }));
  const loadingMessage = message.loading("Sending Document...", 0);
  return submitDataApi
    .sendMultipleDocRequest(data, contractAuthKey)
    .then((response) => {
      loadingMessage();
      message.success(response?.data?.info?.status || "Document Send Succesfully!");
      if (agents) {
        dispatch(transactionLaneDataHandle.triggerIndividualTransactionFetch(true));
        dispatch(transactionLaneDataHandle.getTransactionLaneMethod(data?.offerId, "SELLER", contractAuthKey));
      }
      dispatch(setTransparentDivLoading({
        loadingStatus: false,
        message:""
      }));
    })
    .catch((error) => {
      loadingMessage();
      message.error(error?.response?.data?.message || "Error occured while getting property data!");
      dispatch(setTransparentDivLoading({
        loadingStatus: false,
        message:""
      }));
    });
};


const templateLoading = () => {
  return {
    type: contractStoreConstants.TEMPLATE_DATA_LOADING,
  };
};
const templateError = (error) => {
  return {
    type: contractStoreConstants.TEMPLATE_DATA_FAILURE,
    templateError: error,
  };
};

const templateSuccess = (response) => {
  return {
    type: contractStoreConstants.TEMPLATE_DATA_SUCCESS,
    templateData: response.data.info,
  };
};

const getTemplatesData = ({query}) => {
  return (dispatch, getState) => {
    let accessToken = getState()?.createOffer?.contractAuthKey;
    dispatch(templateLoading());
    submitDataApi
      .templateDataRequest({query, accessToken })
      .then((response) => {
        dispatch(templateSuccess(response));
      })
      .catch((error) => {
        dispatch(templateError(error));
      });
  };
};

export const contractStoreHandle = {
  autoSaveContract,
  validateForm,
  reSetvalidateForm,
  getAddClausesData,
  getAddDocumentsData,
  getTransactionLaneMethod,
  getClientDetailsFun,
  getTransactionReportMethod,
  resetSubmitSuccess,
  resetSignatures,
  copyAsDraftOfferFun,
  invalidateDocument,
  downloadPdfFun,
  setTransparentDivLoading,
  deleteTitleCompanyData,
  SendMultipleDoc,
  getTemplatesData
};
