import { contractConstants } from "./action-types";
import { contractDataApi } from "../utils/api";
import { checkIfDisabled, createOfferContractTypes,   } from "../../Common/commondata/commonData";
import { contractStoreHandle } from "../../ContractStore/state/actions";
import { features } from "../features/features";
import { decryptQuery, formatAndParse } from "../../Common/utils/universalFuntions";
import { closingActions } from "../../TransactionReport/state/actions";
import { message } from "antd";
import moment from "moment";
import calculatePriceTable from "../features/helpers/calculatePriceTable";

/**
 * All Actions for contract Page
 */

/**
 * To update Navbar title action
 * @param {string}
 */

const setUrlIdFun = (urlId) => {
  return {
    type: contractConstants.SET_URL_ID,
    currentUrlId: urlId,
  };
};
const setAuthKeyForContractFun = (key) => {
  return {
    type: contractConstants.SET_AUTH_KEY_FOR_CONTRACT,
    contractAuthKey: key,
  };
};
const setRootLevelDocDataFun = (payload) => {
  return {
    type: contractConstants.SET_READ_ONLY_DATA_FOR_CONTRACT,
    rootDocData: payload,
  };
};
const setLoadingSave = (data) => {
  return {
    type: contractConstants.SET_LOADING_SAVE,
    loadingSave: data,
  };
};

const setError = (data) => {
  return {
    type: contractConstants.VERIFY_OTP_ERROR,
    error: data,
  };
};

const contractError = (error) => {
  return {
    type: contractConstants.CONTRACT_PAGE_FAILURE,
    contractError: error,
    contractLoading: false,
  };
};

const contractSuccess = (response) => {
  return {
    type: contractConstants.CONTRACT_PAGE_SUCCESS,
    contractData: response.data.info,
    contractLoading: false,
  };
};

const getContractData = (id, authKey, contractType, RTDInitiator) => {
  return (dispatch, getState) => {
    // const token = getState().authentication.token;
    contractDataApi
      .contractPageLoadRequest(id, authKey, RTDInitiator)
      .then((response) => {
        dispatch(contractSuccess(response));
        if (contractType !== "NTT") {
          dispatch(contractStoreHandle.getAddClausesData(authKey));
          dispatch(contractStoreHandle.getAddDocumentsData(authKey));
        }
        // dispatch(setLoadingSave(false));
      })
      .catch((error) => {
        dispatch(contractError(error));
        // dispatch(setLoadingSave(false));
      });
  };
};

const getPersistDeatailsDataOnly=(documentId, token)=>{
  return (dispatch, getState) => {
    contractDataApi
      .getPersistdetailsDataRequest(documentId,token)
      .then((response) => {
        let rootDocData = response.data?.info || {};
        delete rootDocData?.transactionData;
        dispatch(contractDataHandle.setRootLevelDocDataFun(rootDocData));
      }).catch((error) => {});
}}
const getPersistDeatailsData = (documentId, buildData, source, persistDateCalendar) => {
  return (dispatch, getState) => {
    contractDataApi
      .getPersistdetailsDataRequest(documentId, buildData.token)
      .then((response) => {
        localStorage.setItem(buildData._id, JSON.stringify(response.data.info?.transactionData));
        let docResponse = response.data?.info;
        let checkForDisable = checkIfDisabled({openFor: buildData?.openFor, contractData: docResponse.contractId, offerStatus: docResponse?.offerData?.status, actualPropertyStatus: docResponse?.propertyData?.status, sellingStatus: docResponse?.offerData?.sellingStatus, buyingStatus: docResponse?.offerData?.buyingStatus, personStatus: docResponse?.personStatus});
        docResponse.checkForDisable = checkForDisable;
        let contractId;
        if (source === "CONTRACT") {
          if (docResponse?.documentType === "TEMPLATE" && !docResponse?.contractId?._id) {
          //  contractId="64f1e5e7c89d8d4ec138c7f7" // old CBSR
           contractId="66ab7a6a7bfcc2a4deedb08d" // new CBSR
          } else {
            contractId=docResponse?.contractId?._id
         }
          dispatch(getContractData(contractId, buildData?.token, docResponse?.contractType, docResponse?.RTDInitiator));
        } 
        dispatch(getCreateOfferSuccess(docResponse, documentId, buildData, persistDateCalendar, docResponse?.offerId));
      })
      .catch((error) => {});
  };
};

// get all create offer params from the id
const getCreateOfferError = (error) => {
  return {
    type: contractConstants.CREATE_OFFER_FAILURE,
    createOfferError: error,
    createOfferLoading: false,
  };
};

const getCreateOfferSuccess = (originalText, documentId, buildData, persistDateCalendar,offerId) => {
  //originalText====Document Response or get Url Response when documentId is not Present
  let combinedData;
  if (documentId) {
    let createOfferDatatemp, tempRootDocData;
    let { transactionData={}, ...rootDocData } = originalText || {};
    let { urlData = {}, ...rootData } = buildData || {};
    createOfferDatatemp = {
      ...(urlData),
      ...(transactionData),
    };
    tempRootDocData = {
      ...(rootData?{...rootData}: {}),
      urlId: buildData._id,
      ...(rootDocData && rootDocData),
    };
    if (persistDateCalendar) {
      createOfferDatatemp["documentId"] = null;
    }
    combinedData = {
      createOfferData: createOfferDatatemp,
      rootDocData: tempRootDocData,
    };
  } else {
    let { urlData={}, ...rootDocData } = originalText || {};
    combinedData = {
      createOfferData: urlData,
      rootDocData: {
        ...(rootDocData && rootDocData),
        urlId: rootDocData?._id,
      },
    };
  }
  const createOfferData = combinedData?.createOfferData||{};
  const rootDocData = combinedData?.rootDocData || {};

  if (rootDocData?.contractType === "NET_SHEET") {
    rootDocData.TotalEstimateNetProceeds = createOfferData?.TotalEstimateNetProceeds;
  }

  let paramsData = {}, priceTableData={};
  if (rootDocData) {
    paramsData = {
      ...createOfferData,
    };
    if (!rootDocData.documentId && rootDocData?.builtForSection === "DOCUMENT" && createOfferContractTypes.includes(rootDocData?.contractType)) {
      const tableValues = {};
      let purchasePrise = formatAndParse(createOfferData["PurchasePrice"]);
      let earnestMoney = formatAndParse((purchasePrise * 1) / 100)
      tableValues["PurchasePrice"] = purchasePrise;
      tableValues["EarnestMoney"] = createOfferData["EarnestMoney"] ? formatAndParse(createOfferData["EarnestMoney"]) : earnestMoney;
      tableValues["NewLoan"] =  formatAndParse((purchasePrise * 95) / 100);
      tableValues["AssumptionBalance"] = 0;
      tableValues["PrivateFinancing"] = 0;
      tableValues["SellerFinancing"] = 0;
      tableValues["AdditionalPriceAmt1"] = 0;
      tableValues["AdditionalPriceAmt2"] = 0;
      tableValues["CashAtClosing"] = formatAndParse((purchasePrise * 4) / 100);
      priceTableData = calculatePriceTable(tableValues);
 
      // date logic
      const currentDate = moment();
      const closingDate = moment(createOfferData.ClosingDate);

      if (closingDate && closingDate.diff(currentDate, "days") > 29) {
        paramsData.AutoFillDates = "AutoFillDates";
        features.autoFillDateLogic(rootDocData.urlId, "Initial");
      }
    } else {
      paramsData.urlId = rootDocData.urlId;
    }
    let temp1Element = JSON.parse(localStorage.getItem(rootDocData.urlId)) || {};
    localStorage.setItem(rootDocData.urlId, JSON.stringify({ ...temp1Element, ...paramsData, ...priceTableData }));
  }
  return {
    type: contractConstants.CREATE_OFFER_SUCCESS,
    ...combinedData,
    createOfferLoading: false,
    offerId:offerId
  };
};
/*
first stape
when the offer document loads it will always fetch url data
*/
const getCreateOfferData = ({urlId,  unmounted, sourceAxios,sendEmail, history}) => {
  return (dispatch) => {
    // dispatch(setLoadingSave(true));
    contractDataApi
      .getCreateOfferDataRequest({urlId,  unmounted, sourceAxios})
      .then((response) => {
        let originalText = decryptQuery(response.data.info);
        if (!originalText) {
          return dispatch(
            setError({
              message: "Accessing link does not exists",
              statusCode: 400,
            })
          );
        }
        console.log("originalText", originalText);
        //In this location, we keep urlId in local storage.
        if (unmounted?.value) {
          return
        }
        localStorage.setItem(urlId, JSON.stringify(originalText.urlData||{}));
        dispatch(setAuthKeyForContractFun(originalText.token));
        
        if (originalText?.contractType === 'TR') {
          dispatch(contractStoreHandle.getTransactionReportMethod({offerId: originalText.offerId, accessToken: originalText.token, contractType: originalText?.contractType}));
          dispatch(closingActions.transactionReportEmail(originalText.offerId, originalText.token));
          dispatch(closingActions.getSharedDocs(originalText.offerId, originalText.token));
          if (originalText.documentId) {
            console.log("A");
            dispatch(getPersistDeatailsData(originalText?.documentId, originalText, "CONTRACT"));
          }  else {
            console.log("B");
            if (originalText?.contractId) {
              dispatch(getContractData(originalText?.contractId, originalText?.token, originalText?.contractType));
            }
             dispatch(getCreateOfferSuccess(originalText, null, null));
          }
          setTimeout(() => {
            dispatch(contractStoreHandle.autoSaveContract({currentUrlId: urlId, contractAuthKey:originalText.token }));
          }, 3000);
        }else {
          if (originalText.documentId) {
            console.log("A");
            // if (Object.keys(originalText?.deletedById).length !== 0 && originalText?.deletedById?._id) {
            //   let isAgent = false;
            //   if (originalText?.openFor === "SELLER_AGENT" || originalText?.openFor === "BUYER_AGENT") {
            //     isAgent = true
            //   }
            //   history.push({
            //     pathname: "/deletedDocumentSuccess",
            //     search: `?isAgent=${isAgent}`,
            //     state: { deletedById: originalText?.deletedById },
            //   });
            // }else{
            // }
            dispatch(getPersistDeatailsData(originalText?.documentId, originalText, "CONTRACT"));
            // offerCreation with template
          }  else {
            console.log("B");
            if (originalText?.contractId) {
              dispatch(getContractData(originalText?.contractId, originalText?.token, originalText?.contractType, originalText?.RTDInitiator));
            }
             dispatch(getCreateOfferSuccess(originalText, null, null));
            if (originalText?.contractType === 'TO') {
              // To take buyers and seller populated data calling extra API for TO form like TR form
              dispatch(contractStoreHandle.getTransactionReportMethod({offerId: originalText.offerId, accessToken: originalText.token, contractType: originalText?.contractType, td: originalText?.urlData, urlId}));
            }
          }
        }
      })
      .catch((error) => {
        if (!unmounted?.value) {
          dispatch(getCreateOfferError(error));
          // dispatch(setLoadingSave(false));
        }
      });
  };
};
const resetCompressedViewData = () => ({
  type: contractConstants.RESET_COMPRESSED_VIEW,
});
const setDrawerPosition = (payload) =>({
  type: contractConstants.SET_DRAWER_POSITION,
  setDrawer:payload,
});

const getEmailContents = (payload, accessToken) =>{
  return (dispatch, getState) => {
    dispatch(getEmailContentsLoading(true));
    contractDataApi.getEmailContentsRequest(payload, accessToken)
    .then((response)=> {
      dispatch(getEmailContentsSuccess(response))
    })
    .catch((error)=> {
      dispatch(getEmailContentsError(true));
    })
    .finally(() => {
      dispatch(getEmailContentsLoading(false));
    });
  }
};

const getEmailContentsSuccess = (response) => {
  return {
    type: contractConstants.GET_EMAIL_CONTENT_SUCCESS,
    setEmailData: response?.data?.info,
    setEmailLoading: false,
    setEmailError: false,
  }
}

const getEmailContentsLoading = (payload) => {
  return {
    type: contractConstants.GET_EMAIL_CONTENT_LOADING,
    setEmailLoading: payload,
    setEmailError: false,
  }
}

const getEmailContentsError = (payload) => {
  return {
    type: contractConstants.GET_EMAIL_CONTENT_ERROR,
    setEmailLoading: false,
    setEmailError: payload,
  }
}
// Get Property Docs
const getClientDocRequestLoading = (error) => {
  return {
    type: contractConstants.GET_CLIENT_DOC_LOADING,
    uploadPdfError: error,
    uploadPdfLoading: false,
  };
};

const getAgentDetailsSuccess = (data) => {
  return {
    type: contractConstants.GET_AGENT_DETAILS_SUCCESS,
    agentDetailsData: data,
  }
}

const getAgentDetailsLoading = (payload) => {
  return {
    type: contractConstants.GET_AGENT_DETAILS_LOADING,
    agentDetailsLoading: payload
  }
}
const setAgentDetailsData = (payload) => {
  return {
    type: contractConstants.SET_AGENT_DETAILS_DATA,
    agentDetailsData: payload
  }
}

const getAgentDetails = ({ query, accessToken }) =>{
  return (dispatch, getState) => {
    dispatch(getAgentDetailsLoading(true));
    contractDataApi.getAgentDataDetailsRequest({ query, accessToken })
      .then((response) => {
        if (response.data.info._id) {
          dispatch(getAgentDetailsSuccess(response.data.info))
        } else {
          message.error("No account exists with this email address.")
      }
    })
    .catch((error)=> {
      message.error(error?.response?.data?.message || "We encountered an issue in fetching the Agemt Details. Please try after some time.");
    })
    .finally(() => {
      dispatch(getAgentDetailsLoading(false));
    });
  }
};






const getClientDocRequestSuccess = (transactionData, contactDocs) => {
  return {
    type: contractConstants.GET_CLIENT_DOC_SUCCESS,
    propertyDocs: transactionData,
    ...(contactDocs && {contactDocs}),
    uploadPdfLoading: false,
  };
};

const getClientDocRequestError = (error) => {
  return {
    type: contractConstants.GET_CLIENT_DOC_ERROR,
    uploadPdfError: error,
    uploadPdfLoading: false,
  };
};

const getPropertyDocsFun = ({params, unmounted, source,contractAuthKey}) => {
  return (dispatch, getState) => {
    dispatch(getClientDocRequestLoading());
    contractDataApi
      .getClientDocRequest({params, source,contractAuthKey})
      .then((response) => {
        if(!unmounted.value){
          if(params?.section === "SEND_DOCUMENT"){
            dispatch(getClientDocRequestSuccess(response?.data?.info?.transactionDocs || [], response?.data?.info?.contactDocs || []));
          }
          else{
            dispatch(getClientDocRequestSuccess(response?.data?.info));
          }
        }
      })
      .catch((error) => {
        if(!unmounted.value){
          dispatch(getClientDocRequestError(error));
          message.error(
            error?.response?.data?.message || "Error occured while getting property docs data!",
          );
        }
      });
  };
};
//Get Person Docs
const getPersonDocsLoading = () => {
  return {
    type: contractConstants.PERSON_DOCS_LOADING,
  };
};
const getPersonDocsError = (error) => {
  return {
    type: contractConstants.PERSON_DOCS_FAILURE,
    personDocsError: error,
  };
};

const getPersonDocsSuccess = (response) => {
  return {
    type: contractConstants.PERSON_DOCS_SUCCESS,
    personDocs: response.data.info,
  };
};

const getPersonDocs = ({customerIds, unmounted, source,contractAuthKey}) => (dispatch) => {
  dispatch(getPersonDocsLoading());
  return contractDataApi
    .getPersonDocsRequest({customerIds, source,contractAuthKey})
    .then((response) => {
      if(!unmounted.value){
        dispatch(getPersonDocsSuccess(response));
      }
    })
    .catch((error) => {
      if(!unmounted.value){
        message.error(error?.response?.data?.message || "We encountered an issue while getting person documents. Please try again later!", 2);
        dispatch(getPersonDocsError(error));
      }
    });
};
export const contractDataHandle = {
  getContractData,
  resetCompressedViewData,
  getCreateOfferData,
  setLoadingSave,
  setError,
  setUrlIdFun,
  setAuthKeyForContractFun,
  setRootLevelDocDataFun,
  setDrawerPosition,
  getEmailContents,
  getAgentDetails,
  setAgentDetailsData,
  getPropertyDocsFun,
  getPersonDocs,
  getPersistDeatailsDataOnly
};
