import { calendarConstants } from "./action-types";
import { calendarDataApi } from "../utils/api";
import { message } from "antd";
import { decryptQuery } from "../../Common/utils/universalFuntions";
import moment from "moment";

/**
 * All Actions for contract Page
 */

/**
 * To update Navbar title action
 * @param {string}
 */

// get all calendar data
const getCalendarDetailsError = (error) => {
  return {
    type: calendarConstants.CALENDAR_DATA_FAILURE,
    calendarDataError: error,
    calendarDataLoading: false,
  };
};

const getCalendarDetailsSuccess = (response) => {
  return {
    type: calendarConstants.CALENDAR_DATA_SUCCESS,
    calendarData: response.data.info,
    calendarDataLoading: false,
  };
};

const getCalendarDetailsLoading = (value) => {
  return {
    type: calendarConstants.GET_CALENDAR_LOADING,
    calendarDataLoading: value
  };
};

const getCalendarDetails = ({dataObject, timestamp, responseType}) => {
  return (dispatch, getState) => {
    dispatch(getCalendarDetailsLoading(true));
    calendarDataApi
      .getCalendarDetailsRequest(dataObject, timestamp, responseType, getState().calendarData.calenderMonthFilter)
      .then((response) => {
        dispatch(getCalendarDetailsSuccess(response));
      })
      .catch((error) => {
        dispatch(getCalendarDetailsError(error));
      });
  };
};

// get all calendar data from the id
const getCreateOfferError = (error) => {
  return {
    type: calendarConstants.CALENDAR_GETDATA_FAILURE,
    calendarDataEncryptError: error,
    calendarDataEncryptLoading: false,
  };
};

const getCreateOfferSuccess = (originalText) => {
  return {
    type: calendarConstants.CALENDAR_GETDATA_SUCCESS,
    calendarDataEncrypt: originalText.urlData,
    calenderMonthFilter: originalText?.urlData?.allAgents || [],
    calendarDataEncryptLoading: false,
  };
};

const getCalendarData = ({id, viewType, source, payloadTimestamp}) => {
  return (dispatch, getState) => {
    calendarDataApi
      .getCalendarDataRequest(id)
      .then((response) => {
        let date = new Date();
        let timestamp = date.getTime();
        let responseType = "YEAR";
        if (source === "TRANSACTION_CALENDAR") {
          responseType = "YEAR";
        } else if (source === "HOME_CALENDAR") {
          responseType = "YEAR";
          timestamp = payloadTimestamp;
        }
        if(viewType === "month"){
          responseType = "MONTH";
        }
        let originalText = decryptQuery(response.data.info);
        console.log("originalText in calendar", originalText);
        dispatch(getCreateOfferSuccess(originalText));
        let persistDate = originalText?.urlData?.persistCalendar?.date?.split("-");
        timestamp = (persistDate?.length === 3) ? moment({year: persistDate[0], month: persistDate[1]}).valueOf() : timestamp;
        dispatch(getCalendarDetails({dataObject: originalText.urlData, timestamp, responseType}));
        if (viewType !== "month") {
          dispatch(getRemindersList(originalText.urlData, date.getFullYear()));
          dispatch(getScheduleList({dataObject: originalText.urlData, year: date.getFullYear(), responseType, source}));
        }
      })
      .catch((error) => {
        dispatch(getCreateOfferError(error));
      });
  };
};

const handleSelectingAgents = (data) => {
  return {
    type: calendarConstants.SELECT_AGENT,
    selectedAgent: data
  };
};

const resetCalendar = () => ({
  type: calendarConstants.RESET_CALENDAR,
});

const getRemindersListSuccess = (response) => {
  return {
    type: calendarConstants.GET_REMINDERS_LIST_SUCCESS,
    payload: response,
  };
};

const getRemindersListError = (error) => {
  return {
    type: calendarConstants.GET_REMINDERS_LIST_FAILURE,
    payload: error,
  };
};

const getRemindersList = (data, year) => {
  return (dispatch, getState) => {
    calendarDataApi
      .getRemindersListRequest(data, year)
      .then((response) => {
        const newData = response?.data?.info?.data;
        dispatch(getRemindersListSuccess(newData));
      })
      .catch((error) => {
        dispatch(getRemindersListError(error));
      });
  };
};

const getScheduleListSuccess = (response) => {
  return {
    type: calendarConstants.GET_SCHEDULE_LIST_SUCCESS,
    payload: response,
  };
};

const getScheduleListError = (error) => {
  return {
    type: calendarConstants.GET_SCHEDULE_LIST_FAILURE,
    payload: error,
  };
};

const getScheduleList = ({dataObject, year, responseType, source}) => {
  return (dispatch, getState) => {
    calendarDataApi
      .getScheduleListRequest({dataObject, year, responseType, source})
      .then((response) => {
        const newData = response?.data?.info?.data;
        dispatch(getScheduleListSuccess(newData));
      })
      .catch((error) => {
        dispatch(getScheduleListError(error));
      });
  };
};

const shareCalendarToClients = ({payloadData, userAuthKey}) => {
  return (dispatch, getState) => {
    dispatch(shareCalendarToClientsLoading());
    calendarDataApi.shareCalendarToClientsRequest({payloadData, userAuthKey})
    .then((response) => {
      dispatch(shareCalendarToClientsLoadingFalse());
      message.success(response?.data?.info?.message || "Calendar Send Successfully.");
    })
    .catch((error) => {
      dispatch(shareCalendarToClientsLoadingFalse());
      message.error(error?.response?.data?.message || 'We encountered some issue while sending the calendar please try again later.');
    });
  }
}

const shareCalendarToClientsLoading = () => ({
  type: calendarConstants.SHARE_CALENDAR_LOADING,
})

const shareCalendarToClientsLoadingFalse = () => ({
  type: calendarConstants.SHARE_CALENDAR_SUCCESS,
})

const editCustomTask = ({taskId, payloadData, urlData, handleCurrentMonth}) => {
  return (dispatch) => {
    dispatch(getCalendarDetailsLoading(true));
    calendarDataApi
      .editCustomTaskRequest({taskId, payloadData, urlData})
      .then((response) => {
        message.success("Updated Successfully!");
        if(handleCurrentMonth){
          handleCurrentMonth();
        }
      })
      .catch((error) => {
        dispatch(getCalendarDetailsLoading(false));
        message.error(error?.response?.data?.message || "We encountered an issue while updating this task item. Please try again later!")
      }
      );
  };
};

export const calendarHandle = {
  resetCalendar,
  getCalendarDetails,
  getCalendarData,
  getRemindersList,
  getScheduleList,
  shareCalendarToClients,
  editCustomTask,
  handleSelectingAgents
};
