import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Image,
  Modal,
  Spin,
  message,
  Checkbox,
  Tooltip,
} from "antd";
import PhoneInput from "react-phone-input-2";
import { BiImageAdd } from "react-icons/bi";
import { firmDetailsHandle } from "../state/action";
import Text from "antd/lib/typography/Text";
import { formatAddress } from "../../Common/utils/ExtraFunctions";
import { useEffect } from "react";

const { Option } = Select;
var debounce
const CreateFirmForm = ({ setShowCreateModal, tag, form,isBrokerageLogoRequired,setIsBrokerageLogoRequired ,brokrageAddress, setBrokrageAddress,officeAddress,setOfficeAddress}) => {
  const dispatch = useDispatch();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [visible, setVisible] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [photoCustomError, setPhotoCustomError] = useState(false);
  const [showCustomError, setShowCustomError] = useState({
    status: false,
    message: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    officeLogo: "",
    // association: "",
    streetNumber: "",
    streetName: "",
    unit: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    faxNumber:"",
    email: "",
    licenseNumber:""
  });

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md:{
        span:8,
      },
      lg:{
        span:8
      }
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md:{
        span:16,
      },
      lg:{
        span:16,
      }
    },
  };

  const { adminRole } = useSelector((state) => state.account);
  const [loading, setLoading] = useState(false);
  const { getFranchiseData, getBrokrageData, postImageUrl, postImageLoading, adminSearchLoading, adminSearchError, adminSearchData } = useSelector((state) => state.firmDetails);

  const getAllAdminsToAssign = (value) => {
    if(debounce){
      clearTimeout(debounce)
    }
    dispatch(firmDetailsHandle.getAdminSearchLoading(false));
    if(value.fullName.length < 3){
      dispatch(firmDetailsHandle.getAdminSearchData([]));
      return
    }
    else{
      dispatch(firmDetailsHandle.getAdminSearchLoading(true));
    }
    debounce = setTimeout(()=>{
      setLoading(false);
      if(value.fullName.length < 3){
        return
      }
      dispatch(firmDetailsHandle.getAdminSearch(value))
    },1500)
  }

  const onFinish = (values) => {
    let unitValue = {};
      if (!values?.unitLabel?.length && values?.unitNumber?.length) {
        unitValue = { unit: `${values?.unitNumber}` };
      } else if (values?.unitLabel?.trim() === "Unit" && values?.unitNumber) {
        unitValue = { unit: `Unit ${values?.unitNumber}` };
      } else if (values?.unitLabel?.trim() !== "Unit" && values?.unitLabel?.length > 0 && values?.unitNumber) {
        unitValue = { unit: `${values?.unitLabel} ${values?.unitNumber}` };
      }

    if (tag === "FRANCHISE") {
      // if (!postImageUrl) {
      //   setShowCustomError({ status: true, message: "Please Enter the Logo" });
      //   return;
      // }
      let data = {
        ...values,
        ...unitValue,
        logo: postImageUrl,
        type: "FRANCHISE",
      };
      delete data?.unitLabel;
      delete data?.unitNumber;

      for (let key in data) {
        if (!data[key]) {
          delete data[key];
        }
      }
      dispatch(firmDetailsHandle.createFranchise(data));
     } else if (tag === "BROKERAGE") {
      let data = {
         ...values,
         ...unitValue,
         logo: postImageUrl,
         type: "BROKERAGE"
         };
        delete data?.unitLabel;
        delete data?.unitNumber;
      for (let key in data) {
        if (!data[key]) {
          delete data[key];
        }
      }
      dispatch(firmDetailsHandle.createFranchise(data));
    } else if (tag === "OFFICE") {
      let data = { ...values, type: "OFFICE" };
      for (let key in data) {
        if (!data[key]) {
          delete data[key];
        }
      }
      dispatch(firmDetailsHandle.createFranchise(data));
    }
    dispatch(firmDetailsHandle.resetImageUrl());
    form.resetFields();
    setShowCreateModal(false);
    setBrokrageAddress("");
    setOfficeAddress("")
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  let handleCustome = (event) => {
    setVisible(true);
    setFileData(event.file);
    setVisible(true);

    // Preview the image before uploading to our server
    const file = event.file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      setPreviewUrl(base64String);
    };
  };

  const HandleCancel = (e) => {
    setVisible(false);
    setBrokrageAddress("")
    setOfficeAddress("")
  };

  const handleCancel = (e) => {
    setVisible(false);
   };

  const handleImageUpload = () => {
    dispatch(firmDetailsHandle.postImage(fileData));
    setVisible(false);
    setPhotoCustomError(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkPhotoInInput = (_, value) => {
    if (!postImageUrl) {
      return Promise.reject(new Error("Please select a photo."));
    }
    return Promise.resolve();
  };



  const handleBrokerageRequired = (value) => {
    setBrokrageAddress(value)

    setIsBrokerageLogoRequired(!value);

}
const handleOfficeRequired = (value) => {
  setOfficeAddress(value)
 }
const handleFillAddress=(e)=>{


  const matchingAddress = getFranchiseData?.find((el) => el._id === brokrageAddress);
   if (matchingAddress && e.target.checked ) {
        form.setFieldsValue({
          streetNumber: matchingAddress?.address.streetNumber,
          streetName: matchingAddress?.address.streetName,
          unit:matchingAddress?.address.unit,
          city:matchingAddress?.address.city,
          state:matchingAddress?.address.state,
          zipCode:matchingAddress?.address.zipCode
        });
}
else if(!e.target.checked){
  form.setFieldsValue({
          streetNumber: "",
          streetName:"",
          unit:"",
          city:"",
          state:"",
          zipCode:""
        });
}
}
const handleFillofficeAddress=(e)=>{
 const matchingAddress = getBrokrageData?.find((el) => el._id === officeAddress);
    if (matchingAddress && e.target.checked ) {
        form.setFieldsValue({
          streetNumber: matchingAddress?.address.streetNumber,
          streetName: matchingAddress?.address.streetName,
          unit:matchingAddress?.address.unit,
          city:matchingAddress?.address.city,
          state:matchingAddress?.address.state,
          zipCode:matchingAddress?.address.zipCode
        });
}
else if(!e.target.checked){
  form.setFieldsValue({
          streetNumber: "",
          streetName:"",
          unit:"",
          city:"",
          state:"",
          zipCode:""
        });
}
}

  useEffect(() => {
    if(visible){
      dispatch(firmDetailsHandle.resetAdminSearchData());
    }
  },[visible])

  return (
    <>
      {/* Modal opens when someone select an image for preview*/}
      <Modal visible={visible} onCancel={handleCancel} footer={[]}>
        {/* Show only when New pic got uploaded */}
        <div
          style={{
            display: previewUrl ? "block" : "none",
            width: "50%",
            margin: "auto",
            textAlign: "center",
          }}
        >
          {/* <div> */}{" "}
          {previewUrl && (
            <Image
              src={`data:image/png;base64,${previewUrl}`}
              width="250px"
              height="200px"
            />
          )}
          {/* </div> */}
          <div
            style={{ marginTop: "30px", textAlign: "center" }}
            wrapperCol={{ offset: 10, span: 14 }}
          >
            <Button
              type="primary"
              onClick={handleImageUpload}
              style={{ marginRight: "15px", marginLeft: "20px" }}
            >
              Save
            </Button>
            <Button type="primary" onClick={HandleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <div style={{ top: 20, height: "400px", overflowY: "auto" }}>
        <Form
          form={form}
          colon={false}
          onFinish={onFinish}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 15 }}
          labelAlign="left"
          {...formItemLayout}
          initialValues={{unitLabel:"Unit"}}
        >
          {postImageLoading ? (
            <div
              style={{
                display: "flex",
                height: "70vh",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin />
            </div>
          ) : (
            <div> 
              {tag === "FRANCHISE" ? (
                <>
                  <Form.Item
                    label="Franchise Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Franchise Name!",
                      },
                    ]}
                  >
                    <Input
                      name={"name"}
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter Name"
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        // validator: checkPhotoInInput,
                      },
                    ]}
                    // 'officeLogo'
                    label="Franchise Logo"
                    name="officeLogo"
                  >
                    <>
                      <Input
                        disabled={postImageUrl}
                        style={
                          {
                            // marginLeft: "4px",
                            // border: "2px solid red",
                            // width: "102%",
                          }
                        }
                        placeholder={postImageUrl ? "" : "Enter Logo Url"}
                        allowClear
                      />
                      {postImageUrl && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: 10,
                            zIndex: "30",
                          }}
                        >
                          <Image
                            width={50}
                            height={"31px"}
                            src={postImageUrl}
                          />
                        </div>
                      )}

                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          right: 4,
                          zIndex: "30",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      >
                        <Upload
                          showUploadList={false}
                          customRequest={handleCustome}
                          beforeUpload={beforeUpload}
                          style={{ fontSize: "20px" }}
                          size={10}
                        >
                          <span style={{ fontSize: "20px", height: "10px" }}>
                            <BiImageAdd
                              style={{
                                fontSize: "34px",
                                fill: "#085191",
                                color: "#085191",
                              }}
                            />
                          </span>
                        </Upload>
                      </div>
                      {showCustomError.status ? (
                        <div style={{ color: "red" }}>
                          {showCustomError.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  </Form.Item>
                </>
              ) : null}
              {tag === "BROKERAGE" ? (
                <>
                  <Form.Item
                    label="Select Franchise"
                    name="parent"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter Franchise Name!",
                      },
                    ]}
                  >
                    <Select defaultValue={"Select Franchise"} allowClear onChange={handleBrokerageRequired}>
                      {getFranchiseData?.map((el) => {
                        return (
                          <Option key={el._id} value={el._id}>
                            {el.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {brokrageAddress&&<Form.Item
                    label={<div style={{display:"flex",width:"100px"}}> Address <Tooltip title={"Autofill Address"}
                    placement="top" color={"#085191"}><Checkbox onChange={handleFillAddress} style={{marginLeft:"20px"}} defaultChecked={false}></Checkbox></Tooltip></div> }
                  >
                     {getFranchiseData?.map((el) => {
                        return (
                          el._id===brokrageAddress?
                          <div><div style={{
                            border:"1px solid lightgray",
                            width:"260px",
                            minHeight:"35px",
                            display:"flex",
                          alignItems:"center",
                          textAlign:"center",
                          overflow: "hidden",
                         textOverflow: "ellipsis",
                         whiteSpace: "nowrap",
                     }}>
                          {brokrageAddress.length>0&&formatAddress (el.address) }</div></div>:<Text></Text>
                        );
                      })}
                  </Form.Item>}
                  <Form.Item
                    rules={[
                      {
                        // required: isBrokerageLogoRequired,
                        // validator : isBrokerageLogoRequired ? checkPhotoInInput : false,
                        // message: "Please Enter the Logo or select Franchise",
                      },
                    ]}
                    // 'BrokerageLogo'
                    label="Brokerage Logo"
                    name="BrokerageLogo"
                  >
                    <>
                      <Input
                        disabled={postImageUrl}
                        style={
                          {
                            // marginLeft: "4px",
                            // border: "2px solid red",
                            // width: "102%",
                          }
                        }
                        placeholder={postImageUrl ? "" : "Enter Logo Url"}
                        allowClear
                      />
                      {postImageUrl && (
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: 10,
                            zIndex: "30",
                          }}
                        >
                          <Image
                            width={50}
                            height={"31px"}
                            src={postImageUrl}
                          />
                        </div>
                      )}

                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          right: 4,
                          zIndex: "30",
                          height: "20px",
                          cursor: "pointer",
                        }}
                      >
                        <Upload
                          showUploadList={false}
                          customRequest={handleCustome}
                          beforeUpload={beforeUpload}
                          style={{ fontSize: "20px" }}
                          size={10}
                        >
                          <span style={{ fontSize: "20px", height: "10px" }}>
                            <BiImageAdd
                              style={{
                                fontSize: "34px",
                                fill: "#085191",
                                color: "#085191",
                              }}
                            />
                          </span>
                        </Upload>
                      </div>
                      {showCustomError.status ? (
                        <div style={{ color: "red" }}>
                          {showCustomError.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  </Form.Item>
                  <Form.Item
                    label="Brokerage Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Brokerage Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Brokerage Name" allowClear />
                  </Form.Item>
                  <Form.Item
                    label="License Number"
                    name="licenseNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter License Number!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter License Number" allowClear />
                  </Form.Item>
                </>
              ) : null}
              {/* {tag === "OFFICE" ? (
                <>
                  <Form.Item
                    label="Select Brokrage"
                    name="parent"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Brokrage Name!",
                      },
                    ]}
                  >
                    <Select defaultValue={"Select Brokrage"} onChange={handleOfficeRequired}>
                      {getBrokrageData?.map((el) => {
                        return (
                          <Option key={el._id} value={el._id}>
                            {el.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  {officeAddress&&<Form.Item
                    label={<div style={{display:"flex",width:"100px"}}> Address <Tooltip title={"Autofill Address"}
                    placement="top" color={"#085191"}><Checkbox onChange={handleFillofficeAddress} style={{marginLeft:"20px"}} defaultChecked={false}></Checkbox></Tooltip></div> }
                  >
                     {getBrokrageData?.map((el) => {
                        return (
                          el._id===officeAddress?
                          <div><div style={{border:"1px solid lightgray",width:"260px",
                          minHeight:"35px",display:"flex",alignItems:"center",textAlign:"center", overflow: "hidden",
                         textOverflow: "ellipsis",
                         whiteSpace: "nowrap",}}>
                          {officeAddress.length>0&&formatAddress (el.address) }</div></div>:<Text></Text>
                        );
                      })}
                  </Form.Item>}
                  <Form.Item
                    label="Office Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please Enter Office Name!" },
                    ]}
                  >
                    <Input
                      style={{
                        flex: 1,
                      }}
                      placeholder="Enter Office Name"
                      allowClear
                    />
                  </Form.Item>
                </>
              ) : null} */}
              {
               ["SUPER_ADMIN", "RS_SUPPORT1"].some(role => adminRole?.includes(role)) ? <Form.Item
                  label="Assign an admin"
                  name="admins"
                >
                  <Select
                    showSearch
                    mode="multiple"
                    value={""}
                    placeholder={`Assign ${tag === "FRANCHISE" ? "Admin Franchise or Brokerage" : tag === "BROKERAGE" ? "Admin Brokerage" : ""}`}
                    // style={props.style}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(e)=>{
                      getAllAdminsToAssign({tag, fullName: e})
                      }}
                    // onChange={()=>console.log("changing")}
                    notFoundContent={null}
                  >
                    {
                      adminSearchLoading ? <Option disabled><div style={{textAlign: "center"}}><Spin size="small"/></div></Option>
                      : adminSearchError ? "...error" : <>
                        {
                          adminSearchData.map((element)=>{
                            return <Option value={element._id}><div>
                                { element.fullName } - {element.adminRole?.includes("ADMIN_FRANCHISE") ? "ADMIN FRANCHISE" : element.adminRole?.includes("ADMIN_BROKERAGE") ? "ADMIN BROKERAGE" : ""}
                              </div><div style={{fontSize: "10px", color: "#858585", marginTop: "-8px"}}>{element.email}</div></Option>
                          })
                        }
                      </>
                    }
                  </Select>
                </Form.Item> : ""
              }
              {/* <Form.Item
                label="Association"
                name="association"
              >
                <Input
                  placeholder="Enter association name"
                  style={{
                    flex: 1,
                  }}
                  allowClear
                />
              </Form.Item> */}
              <Form.Item
                label="Street Number"
                name="streetNumber"
                rules={[
                  // { required: true, message: "Please Enter Street Number! " },
                ]}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Input
                  placeholder="Enter Street Number"
                  style={{
                    flex: 1,
                  }}
                  allowClear
                />
              </Form.Item>
              {/* Street Name */}
              <Form.Item
                label="Street Name"
                name="streetName"
                rules={[
                  // { required: true, message: "Please Enter Street Name! " },
                ]}
                style={{ display: "flex", alignItems: "center" }}
              >
             <Input
                  placeholder="Enter Street Name"
                  style={{
                    flex: 1,
                  }}
                  allowClear
                />
              </Form.Item>

              {/* <Form.Item
                label="Unit"
                name="unit"
              >
                <Input
                  placeholder="Enter Unit"
                  style={{
                    flex: 1,
                  }}
                 allowClear
                />
              </Form.Item> */}

                
                  <Form.Item
                    label="Unit Label"
                    name="unitLabel"
                  >
                    <Input placeholder="Enter unit, apt or suite" 
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label="Unit Number"
                    name="unitNumber"
                  >
                    <Input placeholder="Enter value" 
                      allowClear
                    />
                  </Form.Item>
                

              <Form.Item
                label="City"
                name="city"
                // rules={[{ required: true, message: "Please Enter City!" }]}
              >
                <Input
                  placeholder="Enter City"
                  style={{
                    flex: 1,
                  }}

                  allowClear
                />
              </Form.Item>

              <Form.Item
                label="State"
                name="state"
                // rules={[{ required: true, message: "Please Enter State!" }]}
              >
                <Input
                  placeholder="Enter State"
                  style={{
                    flex: 1,
                  }}

                  allowClear
                />
              </Form.Item>

              <Form.Item
                label="Zip Code"
                name="zipCode"
                hasFeedback
                rules={[
                  // { required: true, message: "Please Enter Zip Code!" },
                  { whitespace: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value?.length) {
                        if (value?.length > 4 && value.length < 7) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please Enter valid Zip Code!")
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input
                  type="number"
                  maxLength={"6"}
                  style={{
                    flex: 1,
                  }}
                  placeholder="Enter Zip Code"

                  allowClear
                />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                hasFeedback
                rules={[
                  {
                    type: "email",
                    message: "Not a valid Email!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Email"
                  style={{
                    flex: 1,
                  }}
                  allowClear
                />
              </Form.Item>

              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                hasFeedback
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value?.length) {
                        if (value?.length === 10) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please Enter 10 digit Phone Number!")
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <PhoneInput
                  inputStyle={{ width: "100%" }}
                  inputClass="phoneInput"
                  country={"us"}
                  onlyCountries={["us"]}
                  maxLength="10"
                  international={false}
                  disableCountryCode={true}
                  placeholder="Enter Phone Number"
                  className="phone-input"
                  // style="color: red !important;"
                  style={{ width: "286px !important" }}
                />
              </Form.Item>

              <Form.Item
                label="Fax Number"
                name="faxNumber"
                hasFeedback
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value?.length) {
                        if (value?.length === 10) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please Enter Fax Number!")
                        );
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <PhoneInput
                  inputStyle={{ width: "100%" }}
                  inputClass="faxInput"
                  country={"us"}
                  onlyCountries={["us"]}
                  maxLength="10"
                  international={false}
                  disableCountryCode={true}
                  placeholder="Enter Fax Number"
                  className="phone-input"
                  // style="color: red !important;"
                  style={{ width: "286px !important" }}
                />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

export default CreateFirmForm;
