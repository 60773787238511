import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;
// Creating a TitleCompany
const createTitleCompanyRequest = (payload) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.post(`${apiURL}/titleOffice`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// Get all titleCompany created by a person
const gettitleCompanyRequest = ({titleCompanyMenu,currentPage=1, pageSize=20}) => {
  let accessTokan = localStorage.getItem("auth");
  if (titleCompanyMenu === "createTitleCompany") {
    return axios.get(
    `${apiURL}/titleOffice?isFromAdmin=${true}&createdOnly=${true}&page=${currentPage}&limit=${pageSize}`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
    }else if(titleCompanyMenu === "verifyTitleCompany"){
      return axios.get(`${apiURL}/titleOffice?isFromAdmin=${true}&verify=${true}&page=${currentPage}&limit=${pageSize}`,{
       headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      });
    }
};
const updateClosuresSalesRepRequest = (payload) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.put(`${apiURL}/updateTitleCompanyDetails`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
export const TitleCompanyApi = {
    createTitleCompanyRequest,
    gettitleCompanyRequest,
    updateClosuresSalesRepRequest
  };
