/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Spin} from "antd";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { closingActions } from "../../TransactionReport/state/actions";
import { contractDataHandle } from "../../CreateOffer/state/actions";
import moment from "moment";
import styles from "../style/SendEmailModal.module.css"
import { customerDocsContractTypes } from "../commondata/commonData";

const DocumentList = ({ isSmallScreen,role, isMediumScreen, data, setData, screenSource, selectedData, section, documentId, selectedStatus, RTDInitiator, offerId, contractAuthKey, rootDocData }) => {
  
  const { getTransactionLaneData } = useSelector((state) => state.rtdTransactionLane);
  const { sharedDocs, sharedDocsLoading, sharedDocsError } = useSelector((state) => state.transactionReport);
  let { propertyDocs, contactDocs, propertDocsLoading, propertDocsError, personDocs, personDocsLoading, personDocsError } = useSelector((state) => state.createOffer, shallowEqual);
  const dispatch = useDispatch();
  // const query = new URLSearchParams(window.location?.search);
  // // const clientId = query?.get("reqId");
 
  const crmSellerDocs = [
    'BDS',
    'DWR',
    'ERTL',
    'ETCT',
    'LCAES_ERTL',
    'LCAES_ETCT',
    "WFD",
  ];

  useEffect(() => {
    let unmounted = {
      value: false,
    };
    let source = axios.CancelToken.source();
    if (customerDocsContractTypes.includes(rootDocData?.contractType)) {
      let localData = JSON.parse(localStorage.getItem(rootDocData.urlId) || "{}") || {};
      let customerIds = [];
      const clientIds = ["BDB", "ERTB", "LCAE", "FRASCONA_BDB"].includes(rootDocData?.contractType) ? localData.Buyers || [] : localData.Sellers || [];
      clientIds?.forEach((client) => {
        if (client?.isCorp) {
          client.signers?.forEach((signer) => {
            if (signer?.personId?.length === 24) customerIds.push(signer.personId);
          });
        } else {
          if (client?.personId?.length === 24) customerIds.push(client.personId);
        }
      });
      dispatch(contractDataHandle.getPersonDocs({ customerIds, unmounted, source, contractAuthKey }));
    } else {
      if (offerId || screenSource === "SELLING_RTD") {
        dispatch(closingActions.getSharedDocs(offerId, contractAuthKey));
      }
      let params = {};
      if (screenSource === "SELLING_RTD") {
        params = {
          ...(selectedData?.offerId ? { offerId: selectedData?.offerId } : {}),
          openFor: screenSource === "SELLING_RTD" ? "SELLER_AGENT" : "BUYER_AGENT",
          section: "SEND_DOCUMENT"
        };
      } else {
        params = {
          ...(rootDocData?.offerId ? { offerId: rootDocData?.offerId } : {}),
          ...(rootDocData?.propertyId ? { propertyId: rootDocData?.propertyId } : {}),
          openFor: screenSource === "CONTRACT_NAV_SELLER_AGENT" ? "SELLER_AGENT" : "BUYER_AGENT",
          section: "SEND_DOCUMENT"
        };
      }
      dispatch(contractDataHandle.getPropertyDocsFun({ params, unmounted, source, contractAuthKey }));
    }

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, []);

  const getClientDocName = (data={}, name="") => {
    let { contractType, transactionData = {} } = data ;
    switch (contractType) {
      case "ERTL":
      case "ETCT":
      case "ERTB": {
        let startDate = transactionData?.ListingPeriodBeginDate ? moment(transactionData?.ListingPeriodBeginDate).format("MM/DD/YYYY, h:mm A") : "";
        let endDate = transactionData?.ListingPeriodEndByDate ? moment(transactionData?.ListingPeriodEndByDate).format("MM/DD/YYYY, h:mm A") : "";
        return startDate && endDate ? `${name} ${startDate} - ${endDate} ` : startDate ? `${name} ${startDate}` : endDate ? `${name} ${endDate}` : `${name}`;
      }
      case "LCAE":
      case "LCAES_ETCT":
      case "LCAES_ERTL": {
        let endDate = transactionData?.ListingPeriodEndByDate ? moment(transactionData?.ListingPeriodEndByDate).format("MM/DD/YYYY, h:mm A") : "";
        return endDate ? `Amend/Extend -  ${endDate}` : `Amend/Extend`;
      }

      default:
        let dateTime = data?.createdAt ? moment(data?.createdAt).format("MM/DD/YYYY, h:mm A") : "";
        return `${name} ${dateTime}`;
    }
  };
  const mapClientDocs = (docsTabDoraDocs, propertyDocsData) => {
    let clientDocuments = [];

    docsTabDoraDocs?.forEach((doc) => {
      if (doc.createdDocuments?.length > 0) {
        doc.createdDocuments.forEach((subDoc) => {
          subDoc.contractName = getClientDocName(subDoc, doc?.name);
          clientDocuments.push(subDoc);
          if (subDoc.matchedDocuments?.length > 0) {
            subDoc.matchedDocuments.forEach((matchDocument) => {
              matchDocument.contractName = getClientDocName(matchDocument);
              matchDocument.isSecondLevel = true;
              clientDocuments.push(matchDocument);
            });
          }
        });
      }
    });

    return clientDocuments;
  };
  const clientDocs = mapClientDocs(personDocs);

  useEffect(() => {
    let propertyDocuments;
    const filteredClientContracts = clientDocs?.filter((contract) => {
      return contract._id === rootDocData?.documentId;
    });
     if (screenSource === "SELLING_RTD") {
      propertyDocuments = propertyDocs?.filter((contract) => {
        return contract?.documentId === documentId;
      });
    }else{
     propertyDocuments = propertyDocs?.filter((contract) => {
      return contract.documentId === rootDocData?.documentId;
    });
  }
    if (filteredClientContracts && filteredClientContracts.length > 0) {
      // Extract both documentIds and sentBy from filtered contracts
      const documentDetails = filteredClientContracts
        .map((contract) => ({
          documentId: contract?._id,
          sentBy:crmSellerDocs.includes(contract?.contractType)?"SELLER_AGENT":"BUYER_AGENT"  // Ensure that the sentBy field exists in your contracts
        }))
        .filter(({ documentId, sentBy }) => documentId !== undefined && sentBy !== undefined); // Filter out any undefined values

      // Update state with the filtered documentDetails
      setData((prevState) => ({
        ...prevState,
        clientDocumentSelected: documentDetails,
      }));
    }

    if (propertyDocuments && propertyDocuments.length > 0) {
      // Extract documentIds from filtered contracts
      const documentIds = propertyDocuments.filter(
        ele =>
          (["ERTS", "LCAES", "FRASCONA_ERTS"].includes(ele?.contractType)) ||
          ele?.hasOwnProperty('isShared') ||
          ele?.offerId
      ).map((contract) => contract.documentId).filter((documentId) => documentId !== undefined);
      // Update state with the filtered documentIds
      setData((prevState) => ({
        ...prevState,
        propertyDocsSelected: documentIds,
      }));
    }

    if (propertyDocuments && propertyDocuments.length > 0) {
      // Extract documentIds from filtered contracts
      const documentIds = propertyDocuments.filter(
        ele =>
          !(["ERTS", "LCAES", "FRASCONA_ERTS"].includes(ele?.contractType)) &&
          !ele?.hasOwnProperty('isShared') &&
          !(ele?.offerId)
      ).map((contract) => contract.documentId).filter((documentId) => documentId !== undefined);
      // Update state with the filtered documentIds
      setData((prevState) => ({
        ...prevState,
        documentWithoutBuyerSelected: documentIds,
      }));
    }
  }, [getTransactionLaneData,personDocs,propertyDocs]);

  const handleClientDocChange = (documentId, sentBy) => {
    // Create a new array of clientDocumentSelected
    let flag = [...data?.clientDocumentSelected];

    // Create an object with documentId
    const newDoc = { documentId, sentBy };

    // Check if the documentId already exists in the array
    const index = flag.findIndex((item) => item.documentId === documentId);

    let newSelectedDocs;
    if (index !== -1) {
      // Remove the existing document object from the array
      newSelectedDocs = flag.filter((_, i) => i !== index);
    } else {
      // Add the new document object to the array
      newSelectedDocs = [...flag, newDoc];
    }

    // Update the state with the new array
    setData({
      ...data,
      clientDocumentSelected: newSelectedDocs,
    });
  };

  const handleCustomerDocs = (documentId) => {
    let flag = [...data?.customerDocumentSelected];
    const value = flag?.includes(documentId) ? { customerDocumentSelected: flag?.filter((id) => id !== documentId) } : { customerDocumentSelected: [...flag, documentId] };
    setData({
      ...data,
      ...value,
    });
  };

  const handleSharedDocs = (documentId) => {
    let flag = [...data?.sharedSelected];
    const value = flag?.includes(documentId) ? { sharedSelected: flag?.filter((id) => id !== documentId) } : { sharedSelected: [...flag, documentId] };
    setData({
      ...data,
      ...value,
    });
  };

  const handlePropertyDocs = (documentId, type) => {
    let flag = [];

    if (type === "PROPETY_DOC") {
      flag = [...data?.propertyDocsSelected];
    } else if (type === "DOCUMENT_WITHOUT_BUYERS") {
      flag = [...data?.documentWithoutBuyerSelected];
    }

    const value = flag.includes(documentId) ? flag.filter((id) => id !== documentId) : [...flag, documentId];

    setData({
      ...data,
      ...(type === "PROPETY_DOC" ? { propertyDocsSelected: value } : {}),
      ...(type === "DOCUMENT_WITHOUT_BUYERS" ? { documentWithoutBuyerSelected: value } : {})
    });
  };

  const handleSelectAll = ({ value, element, type }) => {
    if (value) {
      if (type === "CLIENT DOCUMENT") {
        // Map to include both documentId and sentBy for CLIENT DOCUMENT
        const clientDocumentObjects = element.map((document) => ({
          documentId: document._id, // Assuming _id is used as documentId
          sentBy: crmSellerDocs.includes(document?.contractType)?"SELLER_AGENT":"BUYER_AGENT"
        }));

        setData((prevData) => ({
          ...prevData,
          clientDocumentSelected: clientDocumentObjects,
        }));
      } else {
        // Handle other types
        const allDocumentIds = element.map((document) => (type === "DOCUMENT" ? document.documentId : document._id));

        setData((prevData) => ({
          ...prevData,
          ...(type === "DOCUMENT" ? { documentSelected: allDocumentIds } : type === "SHARED" ? { sharedSelected: allDocumentIds } : type === "DOCUMENT_WITHOUT_BUYERS" ? { documentWithoutBuyerSelected: allDocumentIds } : type === "CUSTOMER_DOCUMENTS" ? { customerDocumentSelected: allDocumentIds } : { propertyDocsSelected: allDocumentIds }),
        }));
      }
    } else {
      // Handle the case where value is false
      setData((prevData) => ({
        ...prevData,
        ...(type === "DOCUMENT" ? { documentSelected: [] } : type === "SHARED" ? { sharedSelected: [] } : type === "CLIENT DOCUMENT" ? { clientDocumentSelected: [] } : type === "DOCUMENT_WITHOUT_BUYERS" ? { documentWithoutBuyerSelected: [] } : type === "CUSTOMER_DOCUMENTS" ? { customerDocumentSelected: [] } : { propertyDocsSelected: [] }),
      }));
    }
  };

  const transactionAndPropertyDocs = propertyDocs?.filter((ele) => ["ERTS", "LCAES", "FRASCONA_ERTS"].includes(ele?.contractType) || ele?.hasOwnProperty("isShared") || ele?.offerId);

  return (
    <div style={{ width: "100%" }}>
      {personDocsLoading ? (
        <div style={{height:"300px", display:"flex", justifyContent:"center", alignItems:"center"}}>
         <Spin size="medium" />
        </div>
      ) : personDocsError ? (
        ""
      ) : (
        clientDocs?.length > 0 && (
          <div>
            <div style={{ overflow: "hidden" }}>
              <div>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                  <p className={styles.documentHeader}>Client Documents:</p>

                  <Checkbox onChange={(e) => handleSelectAll({ value: e.target.checked, element: clientDocs, type: "CLIENT DOCUMENT" })}
                   checked={data?.clientDocumentSelected?.length === clientDocs?.length}
                   style={{width:isSmallScreen?"50%":isMediumScreen?"30%":"15%"}}
                   >
                    <text className={styles.documentHeader} style={{ color: "grey" }}>Select All</text>
                  </Checkbox>
                </div>
                {clientDocs?.map((document, index) => (
                  <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", flexDirection: "column" }}>
                    {document?.isSecondLevel && (
                      <>
                        <div style={{ marginLeft: "20px" }}>
                          <Checkbox style={{ marginRight: "10px" }} checked={data?.clientDocumentSelected?.some((item) => item.documentId === document._id)} onChange={() => handleClientDocChange(document._id, crmSellerDocs.includes(document?.contractType) ? "SELLER_AGENT" : "BUYER_AGENT")}>
                          <text className={styles.docName}> {document.contractName}</text>
                          </Checkbox>
                        </div>
                      </>
                    )}
                    {!document.isSecondLevel && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ marginRight: "10px" }} checked={data?.clientDocumentSelected?.some((item) => item.documentId === document._id)} onChange={() => handleClientDocChange(document._id, crmSellerDocs.includes(document?.contractType) ? "SELLER_AGENT" : "BUYER_AGENT")}>
                        <text className={styles.docName}> {document.contractName}</text>
                        </Checkbox>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}

      {/* // Property documents list  */}
      {propertDocsLoading ? (
        <div className={styles.spin} >
          <Spin size="medium" />
        </div>
      ) : propertDocsError ? (
        ""
      ) : (
        propertyDocs?.length > 0 && (
          <div>
            <div style={{ overflow: "hidden" }}>
              {transactionAndPropertyDocs?.length > 0 ? (
                <div>
                  <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey", }}>
                    <p className={styles.documentHeader}>Transaction & Property Documents:</p>
                    <Checkbox
                      onChange={(e) =>
                        handleSelectAll({
                          value: e.target.checked,
                          element: propertyDocs.filter((ele) => ["ERTS", "LCAES", "FRASCONA_ERTS"].includes(ele?.contractType) || ele?.hasOwnProperty("isShared") || ele?.offerId),
                          type: "PROPETY_DOC",
                        })
                      }
                      style={{width:isSmallScreen?"50%":isMediumScreen?"30%":"15%"}}
                      checked={data?.propertyDocsSelected?.length === propertyDocs.filter((ele) => ["ERTS", "LCAES", "FRASCONA_ERTS"].includes(ele?.contractType) || ele?.hasOwnProperty("isShared") || ele?.offerId).length}
                   >
                      <text className={styles.documentHeader}>Select All</text>
                    </Checkbox>
                  </div>
                  {transactionAndPropertyDocs.map((document, index) => (
                    <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                      <Checkbox style={{ marginRight: "10px" }} checked={data?.propertyDocsSelected?.includes(document._id)} onChange={() => handlePropertyDocs(document._id, "PROPETY_DOC")}>
                       <text className={styles.docName}> {document?.contractId ? document?.documentName : document?.fileName}</text>
                      </Checkbox>
                    </div>
                  ))}
                </div>
              ) : null}

              {/* Section for Documents without Buyers */}
              {propertyDocs.filter((ele) => !(["ERTS", "LCAES", "FRASCONA_ERTS"].includes(ele?.contractType)) && !ele?.hasOwnProperty("isShared") && !ele?.offerId).length > 0 && (
                <div style={{ padding: "10px" }}>
                  <div style={{ display: "flex", height: "40px", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                    <p className={styles.documentHeader}>Documents without Buyers:</p>
                    <Checkbox
                      onChange={(e) =>
                        handleSelectAll({
                          value: e.target.checked,
                          element: propertyDocs.filter((ele) => !(["ERTS", "LCAES", "FRASCONA_ERTS"].includes(ele?.contractType)) && !ele?.hasOwnProperty("isShared") && !ele?.offerId),
                          type: "DOCUMENT_WITHOUT_BUYERS",
                        })
                      }
                      checked={data?.documentWithoutBuyerSelected?.length === propertyDocs.filter((ele) => !(["ERTS", "LCAES", "FRASCONA_ERTS"].includes(ele?.contractType)) && !ele?.hasOwnProperty("isShared") && !ele?.offerId).length}
                      style={{width:isSmallScreen?"50%":isMediumScreen?"30%":"15%"}}
                    >
                      <text className={styles.documentHeader} style={{ color: "grey" }}>Select All</text>
                    </Checkbox>
                  </div>
                  {propertyDocs
                    .filter((ele) => !(["ERTS", "LCAES", "FRASCONA_ERTS"].includes(ele?.contractType)) && !ele?.hasOwnProperty("isShared") && !ele?.offerId)
                    .map((document, index) => (
                      <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ marginRight: "10px" }} checked={data?.documentWithoutBuyerSelected?.includes(document._id)} onChange={() => handlePropertyDocs(document._id, "DOCUMENT_WITHOUT_BUYERS")}>
                        <text className={styles.docName}>{document?.contractId ? document?.documentName : document?.fileName}</text>
                        </Checkbox>
                      </div>
                    ))}
                </div>
              )}
              {
                screenSource !== "CLIENT_DOC" && (contactDocs?.length > 0)  && (
                  <div>
                    <div style={{ overflow: "hidden" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey", padding: "0px 10px 0px 0px" }}>
                        <p>Customer Documents:</p>
                        <Checkbox onChange={(e) => handleSelectAll({ value: e.target.checked, element: contactDocs, type: "CUSTOMER_DOCUMENTS" })} checked={data?.customerDocumentSelected?.length === contactDocs?.length}>
                          <span style={{ color: "grey" }}>Select All</span>
                        </Checkbox>
                      </div>
                      {contactDocs?.map((document, index) => (
                        <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                          <Checkbox style={{ marginRight: "10px" }} checked={data?.customerDocumentSelected?.includes(document._id)} onChange={() => handleCustomerDocs(document._id)}>
                            {document?.documentName} {moment(document?.createdDateMili).format("MM/DD/YYYY")} at {moment(document?.createdDateMili).format("hh:mm A")}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        )
      )}
      {/* shared document list  */}
      {sharedDocsLoading ? (
        <div className={styles.spin}>
          <Spin size="medium" />
        </div>
      ) : sharedDocsError ? (
        ""
      ) : (
        sharedDocs?.length > 0 && (
          <div>
            <div style={{ overflow: "hidden" }}>
              <div style={{ padding: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                  <p className={styles.documentHeader}>Shared Documents:</p>
                  <Checkbox onChange={(e) => handleSelectAll({ value: e.target.checked, element: sharedDocs, type: "SHARED" })} 
                  checked={data?.sharedSelected?.length === sharedDocs?.length}
                  style={{width:isSmallScreen?"50%":isMediumScreen?"30%":"15%"}}
                  >
                    <text className={styles.documentHeader} style={{ color: "grey" }}>Select All</text>
                  </Checkbox>
                </div>
                {sharedDocs?.map((document, index) => (
                  <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                    <Checkbox style={{ marginRight: "10px" }} checked={data?.sharedSelected?.includes(document._id)} onChange={() => handleSharedDocs(document._id)}>
                      {document.fileName}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default DocumentList;
