import { buildClientFullAddress, formatPhoneNumber } from "../../../Common/utils/universalFuntions";

export const addDynamicSellerBuyer = (clientType, clientArray, contractType, td) => {
    // Title order form
    if (contractType === 'TO') {
        let dataToSave = {};
        if (clientType === "Sellers") {
            const sellerContainer = document.getElementById('dynamicSellerContainer');
            if (sellerContainer) {
                let count = 0;
                const sellerDetails = clientArray?.map((seller,i)=>{
                    count = i+1;
                    if (seller?.isCorp && seller?.signers) {
                        return seller?.signers?.map((signer,j) => {
                            let SellerFullNameInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_SellerFullName`;
                            let SellerOfficePhoneNumberInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_SellerOfficePhoneNumber`;
                            let SellerEmailInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_SellerEmail`;
                            let SellerCellPhoneNoInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_SellerCellPhoneNo`;
                            let SellerPhoneNumberInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_SellerPhoneNumber`;
                            let SellerSSNInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_SellerSSN`;
                            let SellerFaxNoInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_SellerFaxNo`;
                            dataToSave = {
                                ...dataToSave,
                                [SellerFullNameInputBoxName]: `${signer?.fullName || signer?.personId?.fullName || ''} [${seller?.fullName ? seller?.fullName : ''}]` || (td[SellerFullNameInputBoxName]) || '',
                                [SellerOfficePhoneNumberInputBoxName]: signer?.personId?.officePhoneNumber?.phoneNumber ? formatPhoneNumber(signer?.personId?.officePhoneNumber?.phoneNumber) : (td[SellerOfficePhoneNumberInputBoxName]) || '',
                                [SellerEmailInputBoxName]: signer?.personId?.email || (td[SellerEmailInputBoxName]) || '',
                                [SellerCellPhoneNoInputBoxName]: signer?.personId?.mobilePhoneNumber?.phoneNumber ? formatPhoneNumber(signer?.personId?.mobilePhoneNumber?.phoneNumber) : (td[SellerCellPhoneNoInputBoxName]) || '',
                                [SellerPhoneNumberInputBoxName]: signer?.personId?.phoneNumber ? formatPhoneNumber(signer?.personId?.phoneNumber) : (td[SellerPhoneNumberInputBoxName]) || '',
                                [SellerSSNInputBoxName]: signer?.personId?.SSN || (td[`${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_SellerSSN`]) || '',
                                [SellerFaxNoInputBoxName]: signer?.personId?.fax ? formatPhoneNumber(signer?.personId?.fax) : (td[SellerFaxNoInputBoxName]) || '',
                            }
                            count = count +j;
                            return `
                                <div class="mt titleCompanyAddressTable">
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="leftTable tb">Seller ${count}:</td>
                                            <td><input type="text" name="${signer?.personId?._id || signer?.key}_SellerFullName" placeholder="Seller FullName" value="${signer?.fullName || signer?.personId?.fullName || ''} [${seller?.fullName ? seller?.fullName : ''}]"></td>
                                        </tr>
                                        <tr>
                                            <td>Phone W:</td>
                                            <td><input class="formatPhoneNumber" type="text" name="${signer?.personId?._id || signer?.key}_SellerOfficePhoneNumber" placeholder="Seller Office PhoneNumber" value="${signer?.personId?.officePhoneNumber?.phoneNumber ? formatPhoneNumber(signer?.personId?.officePhoneNumber?.phoneNumber) : ''}"></td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td><input type="email" name="${signer?.personId?._id || signer?.key}_SellerEmail" placeholder="Seller Email" value="${signer?.personId?.email || ''}"></td>
                                        </tr>
                                    </table>
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="rightTable">Cell:</td>
                                            <td><input class="formatPhoneNumber" type="text" name="${signer?.personId?._id || signer?.key}_SellerCellPhoneNo" placeholder="Seller Cell Phone No." value="${signer?.personId?.mobilePhoneNumber?.phoneNumber ? formatPhoneNumber(signer?.personId?.mobilePhoneNumber?.phoneNumber) : ''}"></td>
                                        </tr>
                                        <tr>
                                            <td>Phone H:</td>
                                            <td><input class="formatPhoneNumber" type="text" name="${signer?.personId?._id || signer?.key}_SellerPhoneNumber" placeholder="Seller PhoneNumber" value="${signer?.personId?.phoneNumber ? formatPhoneNumber(signer?.personId?.phoneNumber) : ''}"></td>
                                        </tr>
                                        <tr>
                                            <td>SSN:</td>
                                            <td><input type="text" name="${signer?.personId?._id || signer?.key}_SellerSSN" placeholder="Seller SSN Number" value="${signer?.personId?.SSN || ''}"></td>
                                        </tr>
                                        <tr>
                                            <td>Fax:</td>
                                            <td><input class="formatPhoneNumber" type="text" name="${signer?.personId?._id || signer?.key}_SellerFaxNo" placeholder="Seller Fax Number" value="${signer?.personId?.fax ? formatPhoneNumber(signer?.personId?.fax) : ''}"></td>
                                        </tr>
                                    </table>
                                </div>
                            `
                        }).join('');
                    } else {
                        let SellerFullNameInputBoxName = `${(typeof seller?.personId === 'string' && seller.personId?.length === 24) ? seller.personId : seller?.personId?._id || seller?.key}_SellerFullName`;
                        let SellerOfficePhoneNumberInputBoxName = `${(typeof seller?.personId === 'string' && seller.personId?.length === 24) ? seller.personId : seller?.personId?._id || seller?.key}_SellerOfficePhoneNumber`;
                        let SellerEmailInputBoxName = `${(typeof seller?.personId === 'string' && seller.personId?.length === 24) ? seller.personId : seller?.personId?._id || seller?.key}_SellerEmail`;
                        let SellerCellPhoneNoInputBoxName = `${(typeof seller?.personId === 'string' && seller.personId?.length === 24) ? seller.personId : seller?.personId?._id || seller?.key}_SellerCellPhoneNo`;
                        let SellerPhoneNumberInputBoxName = `${(typeof seller?.personId === 'string' && seller.personId?.length === 24) ? seller.personId : seller?.personId?._id || seller?.key}_SellerPhoneNumber`;
                        let SellerSSNInputBoxName = `${(typeof seller?.personId === 'string' && seller.personId?.length === 24) ? seller.personId : seller?.personId?._id || seller?.key}_SellerSSN`;
                        let SellerFaxNoInputBoxName = `${(typeof seller?.personId === 'string' && seller.personId?.length === 24) ? seller.personId : seller?.personId?._id || seller?.key}_SellerFaxNo`;
                        dataToSave = {
                            ...dataToSave,
                            [SellerFullNameInputBoxName]: seller?.fullName || seller?.personId?.fullName || (td[SellerFullNameInputBoxName]) || '',
                            [SellerOfficePhoneNumberInputBoxName]: seller?.personId?.officePhoneNumber?.phoneNumber ? formatPhoneNumber(seller?.personId?.officePhoneNumber?.phoneNumber) : (td[SellerOfficePhoneNumberInputBoxName]) || '',
                            [SellerEmailInputBoxName]: seller?.personId?.email || (td[SellerEmailInputBoxName]) || '',
                            [SellerCellPhoneNoInputBoxName]: seller?.personId?.mobilePhoneNumber?.phoneNumber ? formatPhoneNumber(seller?.personId?.mobilePhoneNumber?.phoneNumber) : (td[SellerCellPhoneNoInputBoxName]) || '',
                            [SellerPhoneNumberInputBoxName]: seller?.personId?.phoneNumber ? formatPhoneNumber(seller?.personId?.phoneNumber) : (td[SellerPhoneNumberInputBoxName]) || '',
                            [SellerSSNInputBoxName]: seller?.personId?.SSN || (td[SellerSSNInputBoxName]) || '',
                            [SellerFaxNoInputBoxName]: seller?.personId?.fax ? formatPhoneNumber(seller?.personId?.fax) : (td[SellerFaxNoInputBoxName]) || '',
                        }
                        return `
                            <div class="mt titleCompanyAddressTable">
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="leftTable tb">Seller ${count}:</td>
                                        <td><input type="text" name="${seller?.personId?._id || seller?.key}_SellerFullName" placeholder="Seller FullName" value="${seller?.fullName || seller?.personId?.fullName || ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Phone W:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${seller?.personId?._id || seller?.key}_SellerOfficePhoneNumber" placeholder="Seller Office PhoneNumber" value="${seller?.personId?.officePhoneNumber?.phoneNumber ? formatPhoneNumber(seller?.personId?.officePhoneNumber?.phoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td><input type="email" name="${seller?.personId?._id || seller?.key}_SellerEmail" placeholder="Seller Email" value="${seller?.personId?.email || ''}"></td>
                                    </tr>
                                </table>
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="rightTable">Cell:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${seller?.personId?._id || seller?.key}_SellerCellPhoneNo" placeholder="Seller Cell Phone No." value="${seller?.personId?.mobilePhoneNumber?.phoneNumber ? formatPhoneNumber(seller?.personId?.mobilePhoneNumber?.phoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Phone H:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${seller?.personId?._id || seller?.key}_SellerPhoneNumber" placeholder="Seller PhoneNumber" value="${seller?.personId?.phoneNumber ? formatPhoneNumber(seller?.personId?.phoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>SSN:</td>
                                        <td><input type="text" name="${seller?.personId?._id || seller?.key}_SellerSSN" placeholder="Seller SSN Number" value="${seller?.personId?.SSN || ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Fax:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${seller?.personId?._id || seller?.key}_SellerFaxNo" placeholder="Seller Fax Number" value="${seller?.personId?.fax ? formatPhoneNumber(seller?.personId?.fax) : ''}"></td>
                                    </tr>
                                </table>
                            </div>
                        `
                    }
                });
                sellerContainer.innerHTML = sellerDetails.join("\n");
            }
        }
        else if (clientType === "Buyers") {
            const buyerContainer = document.getElementById('dynamicBuyerContainer');
            if (buyerContainer) {
                let count = 0;
                const buyerDetails = clientArray?.map((buyer,i)=>{
                    count = i+1;
                    if (buyer?.isCorp && buyer?.signers) {
                        return buyer?.signers?.map((signer,j) => {
                            let BuyerFullNameInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_BuyerFullName`;
                            let BuyerOfficePhoneNumberInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_BuyerOfficePhoneNo`;
                            let BuyerEmailInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_BuyerEmail`;
                            let BuyerCellPhoneNoInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_BuyerCellPhoneNo`;
                            let BuyerPhoneNumberInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_BuyerPhoneNumber`;
                            let BuyerSSNInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_BuyerSSN`;
                            let BuyerFaxNoInputBoxName = `${(typeof signer?.personId === 'string' && signer.personId?.length === 24) ? signer.personId : signer?.personId?._id || signer?.key}_BuyerFaxNo`;
                            dataToSave = {
                                ...dataToSave,
                                [BuyerFullNameInputBoxName]: `${signer?.fullName || signer?.personId?.fullName || ''} [${buyer?.fullName ? buyer?.fullName : ''}]` || (td[BuyerFullNameInputBoxName]) || '',
                                [BuyerOfficePhoneNumberInputBoxName]: signer?.personId?.officePhoneNumber?.phoneNumber ? formatPhoneNumber(signer?.personId?.officePhoneNumber?.phoneNumber) : (td[BuyerOfficePhoneNumberInputBoxName]) || '',
                                [BuyerEmailInputBoxName]: signer?.personId?.email || (td[BuyerEmailInputBoxName]) || '',
                                [BuyerCellPhoneNoInputBoxName]: signer?.personId?.mobilePhoneNumber?.phoneNumber ? formatPhoneNumber(signer?.personId?.mobilePhoneNumber?.phoneNumber) : (td[BuyerCellPhoneNoInputBoxName]) || '',
                                [BuyerPhoneNumberInputBoxName]: signer?.personId?.phoneNumber ? formatPhoneNumber(signer?.personId?.phoneNumber) : (td[BuyerPhoneNumberInputBoxName]) || '',
                                [BuyerSSNInputBoxName]: signer?.personId?.SSN || (td[BuyerSSNInputBoxName]) || '',
                                [BuyerFaxNoInputBoxName]: signer?.personId?.fax ? formatPhoneNumber(signer?.personId?.fax) : (td[BuyerFaxNoInputBoxName]) || '',
                            }
                            count = count+j;
                            return `
                            <div class="mt titleCompanyAddressTable">
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="leftTable tb">Buyer ${count}:</td>
                                        <td><input type="text" name="${signer?.personId?._id || signer?.key}_BuyerFullName" placeholder="Buyer FullName" value="${signer?.fullName || signer?.personId?.fullName || ''} [${buyer?.fullName ? buyer?.fullName : ''}]"></td>
                                    </tr>
                                    <tr>
                                        <td>Home Ph:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${signer?.personId?._id || signer?.key}_BuyerPhoneNumber" placeholder="Buyer PhoneNumber" value="${signer?.personId?.phoneNumber ? formatPhoneNumber(signer?.personId?.phoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Fax/Work:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${signer?.personId?._id || signer?.key}_BuyerOfficePhoneNo" placeholder="Buyer Office Ph. No." value="${signer?.personId?.officePhoneNumber?.phoneNumber ? formatPhoneNumber(signer?.personId?.officePhoneNumber?.phoneNumber) : ''}"></td>
                                    </tr>
                                </table>
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="rightTable">Cell Ph:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${signer?.personId?._id || signer?.key}_BuyerCellPhoneNo" placeholder="Buyer Cell Phone No." value="${signer?.personId?.mobilePhoneNumber?.phoneNumber ? formatPhoneNumber(signer?.personId?.mobilePhoneNumber?.phoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td><input type="email" name="${signer?.personId?._id || signer?.key}_BuyerEmail" placeholder="Buyer Email Address" value="${signer?.personId?.email || ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>SSN:</td>
                                        <td><input type="text" name="${signer?.personId?._id || signer?.key}_BuyerSSN" placeholder="Buyer SSN Number" value="${signer?.personId?.SSN || ''}"></td>
                                    </tr>
                                </table>
                            </div>
                        `
                        }).join('');
                    } else {
                        let BuyerFullNameInputBoxName = `${(typeof buyer?.personId === 'string' && buyer.personId?.length === 24) ? buyer.personId : buyer?.personId?._id || buyer?.key}_BuyerFullName`;
                        let BuyerOfficePhoneNumberInputBoxName = `${(typeof buyer?.personId === 'string' && buyer.personId?.length === 24) ? buyer.personId : buyer?.personId?._id || buyer?.key}_BuyerOfficePhoneNo`;
                        let BuyerEmailInputBoxName = `${(typeof buyer?.personId === 'string' && buyer.personId?.length === 24) ? buyer.personId : buyer?.personId?._id || buyer?.key}_BuyerEmail`;
                        let BuyerCellPhoneNoInputBoxName = `${(typeof buyer?.personId === 'string' && buyer.personId?.length === 24) ? buyer.personId : buyer?.personId?._id || buyer?.key}_BuyerCellPhoneNo`;
                        let BuyerPhoneNumberInputBoxName = `${(typeof buyer?.personId === 'string' && buyer.personId?.length === 24) ? buyer.personId : buyer?.personId?._id || buyer?.key}_BuyerPhoneNumber`;
                        let BuyerSSNInputBoxName = `${(typeof buyer?.personId === 'string' && buyer.personId?.length === 24) ? buyer.personId : buyer?.personId?._id || buyer?.key}_BuyerSSN`;
                        let BuyerFaxNoInputBoxName = `${(typeof buyer?.personId === 'string' && buyer.personId?.length === 24) ? buyer.personId : buyer?.personId?._id || buyer?.key}_BuyerFaxNo`;

                        dataToSave = {
                            ...dataToSave,
                            [BuyerFullNameInputBoxName]: buyer?.fullName || buyer?.personId?.fullName || (td[BuyerFullNameInputBoxName]) || '',
                            [BuyerOfficePhoneNumberInputBoxName]: buyer?.personId?.officePhoneNumber?.phoneNumber ? formatPhoneNumber(buyer?.personId?.officePhoneNumber?.phoneNumber) : (td[BuyerOfficePhoneNumberInputBoxName]) || '',
                            [BuyerEmailInputBoxName]: buyer?.personId?.email || (td[BuyerEmailInputBoxName]) || '',
                            [BuyerCellPhoneNoInputBoxName]: buyer?.personId?.mobilePhoneNumber?.phoneNumber ? formatPhoneNumber(buyer?.personId?.mobilePhoneNumber?.phoneNumber) : (td[BuyerCellPhoneNoInputBoxName]) || '',
                            [BuyerPhoneNumberInputBoxName]: buyer?.personId?.phoneNumber ? formatPhoneNumber(buyer?.personId?.phoneNumber) : (td[BuyerPhoneNumberInputBoxName]) || '',
                            [BuyerSSNInputBoxName]: buyer?.personId?.SSN || (td[BuyerSSNInputBoxName]) || '',
                            [BuyerFaxNoInputBoxName]: buyer?.personId?.fax ? formatPhoneNumber(buyer?.personId?.fax) : (td[BuyerFaxNoInputBoxName]) || '',
                        }
                        return `
                            <div class="mt titleCompanyAddressTable">
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="leftTable tb">Buyer ${i+1}:</td>
                                        <td><input type="text" name="${buyer?.personId?._id || buyer?.key}_BuyerFullName" placeholder="Buyer FullName" value="${buyer?.personId?.fullName || buyer?.fullName || ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Home Ph:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${buyer?.personId?._id || buyer?.key}_BuyerPhoneNumber" placeholder="Buyer PhoneNumber" value="${buyer?.personId?.phoneNumber ? formatPhoneNumber(buyer?.personId?.phoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Fax/Work:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${buyer?.personId?._id || buyer?.key}_BuyerOfficePhoneNo" placeholder="Buyer Office Ph. No." value="${buyer?.personId?.officePhoneNumber?.phoneNumber ? formatPhoneNumber(buyer?.personId?.officePhoneNumber?.phoneNumber) : ''}"></td>
                                    </tr>
                                </table>
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="rightTable">Cell Ph:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${buyer?.personId?._id || buyer?.key}_BuyerCellPhoneNo" placeholder="Buyer Cell Phone No." value="${buyer?.personId?.mobilePhoneNumber?.phoneNumber ? formatPhoneNumber(buyer?.personId?.mobilePhoneNumber?.phoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td><input type="email" name="${buyer?.personId?._id || buyer?.key}_BuyerEmail" placeholder="Buyer Email Address" value="${buyer?.personId?.email || ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>SSN:</td>
                                        <td><input type="text" name="${buyer?.personId?._id || buyer?.key}_BuyerSSN" placeholder="Buyer SSN Number" value="${buyer?.personId?.SSN || ''}"></td>
                                    </tr>
                                </table>
                            </div>
                        `
                    }                   
                });
                buyerContainer.innerHTML = buyerDetails.join("\n");
            }
        }
        else if (clientType === 'BuyerAgents') {
            const buyerAgentsDivFromTO = document.getElementById('buyerAgentsContainer');
            if (clientArray && buyerAgentsDivFromTO) {
                buyerAgentsDivFromTO.innerHTML = clientArray?.map((record) => {
                    let agentId;
                    if (record?.personId?.length === 24) {
                        agentId = record?.personId;
                    } else if (record?.key) {
                        agentId = record?.key;
                    }
                    return `
                        <table class="tableWidth2">
                            <tr>
                                <td class="rightTable">Selling Co.:</td>
                                <td><input type="text" name="${agentId}_firmName_buying" value="${record?.firmName || ''}" placeholder="Selling Company Name"></td>
                            </tr>
                            <tr>
                                <td>Brokerage ID:</td>
                                <td><input type="text" name="${agentId}_firmLicense_buying" value="${record?.firmLicense || ''}" placeholder="Buyer Agent Brokerage ID/License No."></td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>
                                    <span class="grow-with-content">
                                        <textarea class="ta-like grow" name="${agentId}_address_buying" placeholder="Address">${record?.address || ''}</textarea>
                                    </span>
                                <!-- <input type="text" name="BuyerBrokerAddress1" placeholder="Address line 1"> -->
                                </td>
                            </tr>
                            <tr>
                                <td>Commission:</td>
                                <td><input type="text" name="${agentId}_commission_buying" placeholder="Buyer Agent Commission"></td>
                            </tr>
                            <tr>
                                <td>Paid By:</td>
                                <td><input type="text" name="${agentId}_commissionPaidBy_buying" placeholder="Commission Paid By"></td>
                            </tr>
                            <tr>
                                <td>Agent:</td>
                                <td><input type="text" name="${agentId}_fullName_buying" value="${record?.fullName || ''}" placeholder="Buyer Agent Name"></td>
                            </tr>
                            <tr>
                                <td>Agent ID:</td>
                                <td><input type="text" name="${agentId}_license_buying" value="${record?.license || ''}" placeholder="Buyer Agent ID/License No."></td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td><input class="formatPhoneNumber" type="text" name="${agentId}_phoneNumber_buying" value="${record?.phoneNumber || ''}" placeholder="Buyer Agent Phone No."></td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td><input type="email" name="${agentId}_email_buying" value="${record?.email || ''}" placeholder="Buyer Agent Email"></td>
                            </tr>
                            <tr>
                                <td>Cell Phone:</td>
                                <td><input class="formatPhoneNumber" type="text" name="${agentId}_cellPhoneNumber_buying" placeholder="Buyer Agent Cell Phone No."></td>
                            </tr>
                            <tr>
                                <td>Fax:</td>
                                <td><input class="formatPhoneNumber" type="text" name="${agentId}_faxNumber_buying" value="${record?.faxNumber || ''}" placeholder="Buyer Agent Fax No."></td>
                            </tr>
                            <tr>
                                <td>Other Request:</td>
                                <td><input type="text" name="${agentId}_otherRequest_buying" placeholder="Buyer Agent Other Request"></td>
                            </tr>
                        </table>
                    `
                }).join(`<div class="sectionTitle" style="margin: 5px 0;"></div>`)
            }
        }
        else if (clientType === 'SellerAgents') {
            const sellerAgentsDivFromTO = document.getElementById('sellerAgentsContainer');
            if (clientArray && sellerAgentsDivFromTO) {
                sellerAgentsDivFromTO.innerHTML = clientArray?.map((record) => {
                    let agentId;
                    if (record?.personId?.length === 24) {
                        agentId = record?.personId;
                    } else if (record?.key) {
                        agentId = record?.key
                    }
                    return `
                        <table class="tableWidth2">
                            <tr>
                                <td class="leftTable">Listing Co.:</td>
                                <td><input type="text" name="${agentId}_firmName_selling" value="${record?.firmName || ''}" placeholder="Listing Company Name"></td>
                            </tr>
                            <tr>
                                <td>Brokerage ID:</td>
                                <td><input type="text" name="${agentId}_firmLicense_selling" value="${record?.firmLicense || ''}" placeholder="Seller Agent Brokerage ID/License No."></td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>
                                    <span class="grow-with-content">
                                        <textarea class="ta-like grow" name="${agentId}_address_selling" placeholder="Address">${record?.address || ''}</textarea>
                                    </span>
                                <!-- <input type="text" name="SellerBrokerAddress1" placeholder="Address line 1"> -->
                                </td>
                            </tr>
                            <tr>
                                <td>Commission:</td>
                                <td><input type="text" name="${agentId}_commission_selling" placeholder="Seller Agent Commission"></td>
                            </tr>
                            <tr>
                                <td>Paid By:</td>
                                <td><input type="text" name="${agentId}_commissionPaidBy_selling" placeholder="Commission Paid By"></td>
                            </tr>
                            <tr>
                                <td>Agent:</td>
                                <td><input type="text" name="${agentId}_fullName_selling" value="${record?.fullName || ''}" placeholder="Seller Agent Name"></td>
                            </tr>
                            <tr>
                                <td>Agent ID:</td>
                                <td><input type="text" name="${agentId}_license_selling" value="${record?.license || ''}" placeholder="Seller Agent ID/License No."></td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td><input class="formatPhoneNumber" type="text" name="${agentId}_phoneNumber_selling" value="${record?.phoneNumber || ''}" placeholder="Seller Agent Phone No."></td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td><input type="email" name="${agentId}_email_selling" value="${record?.email || ''}" placeholder="Seller Agent Email"></td>
                            </tr>
                            <tr>
                                <td>Cell Phone:</td>
                                <td><input class="formatPhoneNumber" type="text" name="${agentId}_cellPhoneNumber_selling" placeholder="Seller Agent Cell Phone No."></td>
                            </tr>
                            <tr>
                                <td>Fax:</td>
                                <td><input class="formatPhoneNumber" type="text" name="${agentId}_faxNumber_selling" value="${record?.faxNumber || ''}" placeholder="Seller Agent Fax No."></td>
                            </tr>
                            <tr>
                                <td>Other Request:</td>
                                <td><input type="text" name="${agentId}_otherRequest_selling" placeholder="Seller Agent Other Request"></td>
                            </tr>
                        </table>
                    `
                }).join(`<div class="sectionTitle" style="margin: 5px 0;"></div>`)
            }
        }
        return dataToSave;
    }
    
    // Transaction Report form
    else if (contractType === 'TR') {
        if (clientType === "Sellers") {
            const sellerContainer = document.getElementById('dynamicSellerContainer');
            if (sellerContainer) {
                let count = 0;
                const sellerDetails = clientArray?.map((seller,i)=>{
                    count = i+1;
                    if (seller?.isCorp && seller?.signers) {
                        return seller?.signers?.map((signer,j) => {
                            count = count +j;
                            return `
                                <div class="mt CompanyAddressTable">
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="leftTable tb">Seller ${count}:</td>
                                            <td><input type="text" name="${signer?.personId?._id || signer?.key}_SellerFullName"
                                                    placeholder="Seller FullName"
                                                    value="${signer?.fullName || signer?.personId?.fullName || ''} [${seller?.fullName ? seller?.fullName : ''}]" disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td><input type="text" name="${signer?.personId?._id || signer?.key}_SellerEmail"
                                                    placeholder="Seller Email" value="${signer?.personId?.email ? signer?.personId?.email : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>SSN:</td>
                                            <td><input type="text" name="${signer?.personId?._id || signer?.key}_SellerSSN"
                                                    placeholder="Seller SSN Number"
                                                    value="${signer?.personId?.SSN ? signer?.personId?.SSN : ''}" disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Address:</td>
                                            <td><input type="text" name="${signer?.personId?._id || signer?.key}_SellerAddress" value="${(signer?.personId?.addressId && buildClientFullAddress(signer?.personId?.addressId)) || ''}" placeholder="Seller Address" disabled></td>
                                        </tr>
                                    </table>
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="rightTable">Home Ph.:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?.personId?._id || signer?.key}_SellerPhoneNumber" placeholder="Seller PhoneNumber"
                                                    value="${signer?.personId?.phoneNumber ? formatPhoneNumber(signer?.personId?.phoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Fax/Work:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?.personId?._id || signer?.key}_SellerOfficePhoneNumber"
                                                    placeholder="Seller Office PhoneNumber"
                                                    value="${signer?.personId?.officePhoneNumber?.phoneNumber ? formatPhoneNumber(signer?.personId?.officePhoneNumber?.phoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Mobile Ph.:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?.personId?._id || signer?.key}_SellerCellPhoneNo"
                                                    placeholder="Seller Cell Phone No."
                                                    value="${signer?.personId?.mobilePhoneNumber?.phoneNumber ? formatPhoneNumber(signer?.personId?.mobilePhoneNumber?.phoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                    </table>
                                </div>
                            `
                        }).join('');
                    } else {
                        return `
                            <div class="mt CompanyAddressTable">
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="leftTable tb">Seller ${count}:</td>
                                        <td><input type="text" name="${seller?.personId?._id || seller?.key}_SellerFullName"
                                                placeholder="Seller FullName"
                                                value="${seller?.fullName || seller?.personId?.fullName || ''}" disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td><input type="text" name="${seller?.personId?._id || seller?.key}_SellerEmail"
                                                placeholder="Seller Email" value="${seller?.personId?.email ? seller?.personId?.email : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>SSN:</td>
                                        <td><input type="text" name="${seller?.personId?._id || seller?.key}_SellerSSN"
                                                placeholder="Seller SSN Number"
                                                value="${seller?.personId?.SSN ? seller?.personId?.SSN : ''}" disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Address:</td>
                                        <td><input type="text" name="${seller?.personId?._id || seller?.key}_SellerAddress" value="${(seller?.personId?.addressId && buildClientFullAddress(seller?.personId?.addressId)) || ''}" placeholder="Seller Address" disabled></td>
                                    </tr>
                                </table>
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="rightTable">Home Ph.:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${seller?.personId?._id || seller?.key}_SellerPhoneNumber" placeholder="Seller PhoneNumber"
                                                value="${seller?.personId?.phoneNumber ? formatPhoneNumber(seller?.personId?.phoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Fax/Work:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${seller?.personId?._id || seller?.key}_SellerOfficePhoneNumber"
                                                placeholder="Seller Office PhoneNumber"
                                                value="${seller?.personId?.officePhoneNumber?.phoneNumber ? formatPhoneNumber(seller?.personId?.officePhoneNumber?.phoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Mobile Ph.:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${seller?.personId?._id || seller?.key}_SellerCellPhoneNo"
                                                placeholder="Seller Cell Phone No."
                                                value="${seller?.personId?.mobilePhoneNumber?.phoneNumber ? formatPhoneNumber(seller?.personId?.mobilePhoneNumber?.phoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                </table>
                            </div>
                        `
                    }
                });
                sellerContainer.innerHTML = sellerDetails.join("\n");
            }
        }
        else if (clientType === "Buyers") {
            const buyerContainer = document.getElementById('dynamicBuyerContainer');
            if (buyerContainer) {
                let count = 0;
                const buyerDetails = clientArray?.map((buyer,i)=>{
                    count = i+1;
                    if (buyer?.isCorp && buyer?.signers) {
                        return buyer?.signers?.map((signer,j) => {
                            count = count+j
                            return `
                                <div class="mt CompanyAddressTable">
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="leftTable tb">Buyer ${count}:</td>
                                            <td><input type="text" name="${signer?.personId?._id || signer?.key}_BuyerFullName" placeholder="Buyer FullName"
                                                    value="${signer?.fullName || signer?.personId?.fullName || ''} [${buyer?.fullName ? buyer?.fullName : ''}]" disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td><input type="text" name="${signer?.personId?._id || signer?.key}_BuyerEmail"
                                                    placeholder="Buyer Email Address"
                                                    value="${signer?.personId?.email ? signer?.personId?.email : ''}" disabled></td>
                                        </tr>
                                        <tr>
                                            <td>SSN:</td>
                                            <td><input type="text" name="${signer?.personId?._id || signer?.key}_BuyerSSN"
                                                    placeholder="Buyer SSN Number" value="${signer?.personId?.SSN ? signer?.personId?.SSN : ''}"
                                                    disabled></td>
                                        </tr>
        
                                        <tr>
                                            <td>Address:</td>
                                            <td><input type="text" name="${signer?.personId?._id || signer?.key}_BuyerAddress" value="${(signer?.personId?.addressId && buildClientFullAddress(signer?.personId?.addressId)) || ''}" placeholder="Buyer Address" disabled>
                                            </td>
                                        </tr>
                                    </table>
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="rightTable">Home Ph.:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?.personId?._id || signer?.key}_BuyerPhoneNumber" placeholder="Buyer PhoneNumber"
                                                    value="${signer?.personId?.phoneNumber ? formatPhoneNumber(signer?.personId?.phoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Fax/Work:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?.personId?._id || signer?.key}_BuyerOfficePhoneNo"
                                                    placeholder="Buyer Office Ph. No."
                                                    value="${signer?.personId?.officePhoneNumber?.phoneNumber ? formatPhoneNumber(signer?.personId?.officePhoneNumber?.phoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Mobile Ph.:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?.personId?._id || signer?.key}_BuyerCellPhoneNo" placeholder="Buyer Cell Phone No."
                                                    value="${signer?.personId?.mobilePhoneNumber?.phoneNumber ? formatPhoneNumber(signer?.personId?.mobilePhoneNumber?.phoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
        
                                    </table>
                                </div>
                            `
                        }).join('');
                    } else {
                        return `
                            <div class="mt CompanyAddressTable">
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="leftTable tb">Buyer ${count}:</td>
                                        <td><input type="text" name="${buyer?.personId?._id || buyer?.key}_BuyerFullName" placeholder="Buyer FullName"
                                                value="${buyer?.fullName || buyer?.personId?.fullName || ''}" disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td><input type="text" name="${buyer?.personId?._id || buyer?.key}_BuyerEmail"
                                                placeholder="Buyer Email Address"
                                                value="${buyer?.personId?.email ? buyer?.personId?.email : ''}" disabled></td>
                                    </tr>
                                    <tr>
                                        <td>SSN:</td>
                                        <td><input type="text" name="${buyer?.personId?._id || buyer?.key}_BuyerSSN"
                                                placeholder="Buyer SSN Number" value="${buyer?.personId?.SSN ? buyer?.personId?.SSN : ''}"
                                                disabled></td>
                                    </tr>

                                    <tr>
                                        <td>Address:</td>
                                        <td><input type="text" name="${buyer?.personId?._id || buyer?.key}_BuyerAddress" value="${(buyer?.personId?.addressId && buildClientFullAddress(buyer?.personId?.addressId)) || ''}" placeholder="Buyer Address" disabled>
                                        </td>
                                    </tr>
                                </table>
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="rightTable">Home Ph.:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${buyer?.personId?._id || buyer?.key}_BuyerPhoneNumber" placeholder="Buyer PhoneNumber"
                                                value="${buyer?.personId?.phoneNumber ? formatPhoneNumber(buyer?.personId?.phoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Fax/Work:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${buyer?.personId?._id || buyer?.key}_BuyerOfficePhoneNo"
                                                placeholder="Buyer Office Ph. No."
                                                value="${buyer?.personId?.officePhoneNumber?.phoneNumber ? formatPhoneNumber(buyer?.personId?.officePhoneNumber?.phoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Mobile Ph.:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${buyer?.personId?._id || buyer?.key}_BuyerCellPhoneNo" placeholder="Buyer Cell Phone No."
                                                value="${buyer?.personId?.mobilePhoneNumber?.phoneNumber ? formatPhoneNumber(buyer?.personId?.mobilePhoneNumber?.phoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                </table>
                            </div>
                        `
                    }
                });
                buyerContainer.innerHTML = buyerDetails.join("\n");
            }
        }
        else if (clientType === 'BuyerAgents') {
            const buyerAgentsDivFromTR = document.getElementById('buyerAgentsContainer');
            if (clientArray && buyerAgentsDivFromTR) {
                buyerAgentsDivFromTR.innerHTML = clientArray?.map((record) => {
                    let agentId;
                    if (record?.personId?.length === 24) {
                        agentId = record?.personId;
                    } else if (record?.key) {
                        agentId = record?.key;
                    }
                    return `
                        <table class="tableWidth2">
                            <tr>
                                <td class="rightTable">Brokerage Name:</td>
                                <td><input type="text" name="${agentId}_firmName" value="${record?.firmName || ''}"
                                        placeholder="Selling Company Name" disabled></td>
                            </tr>
                            <tr>
                                <td>Brokerage ID:</td>
                                <td><input type="text" name="${agentId}_firmLicense" value="${record?.firmLicense || ''}"
                                        placeholder="Buyer Agent Office ID/License No." disabled></td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>
                                    <span class="grow-with-content">
                                        <textarea class="ta-like grow" name="${agentId}_address" placeholder="Address" disabled>${record?.address || ''}</textarea>
                                    </span>
                                <!-- <input type="text" name="${agentId}_address" value="${record?.address || ''}" placeholder="Address" disabled> -->
                                </td>

                            </tr>
                            <tr>
                                <td>Agent:</td>
                                <td><input type="text" name="${agentId}_fullName" placeholder="Buyer Agent Name" value="${record?.fullName || ''}"
                                        disabled></td>
                            </tr>
                            <tr>
                                <td>Agent ID:</td>
                                <td><input type="text" name="${agentId}_license" value="${record?.license || ''}"
                                        placeholder="Buyer Agent ID/License No." disabled></td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td><input class="formatPhoneNumber" type="text"
                                        name="${agentId}_phoneNumber" placeholder="Buyer Agent Phone No." value="${record?.phoneNumber || ''}"
                                        disabled></td>
                            </tr>
                            <tr>
                                <td>Fax:</td>
                                <td><input class="formatPhoneNumber" type="text"
                                        name="${agentId}_faxNumber" value="${record?.faxNumber || ''}" placeholder="Buyer Agent Fax No."
                                        disabled></td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td><input type="text" name="${agentId}_email" value="${record?.email || ''}"
                                        placeholder="Buyer Agent Email" disabled></td>
                            </tr>
                        </table>
                    `
                }).join(`<div class="sectionTitle" style="margin: 5px 0;"></div>`)
            }
        }
        else if (clientType === 'SellerAgents') {
            const sellerAgentsDivFromTR = document.getElementById('sellerAgentsContainer');
            if (clientArray && sellerAgentsDivFromTR) {
                sellerAgentsDivFromTR.innerHTML = clientArray?.map((record) => {
                    return `
                        <table class="tableWidth2">
                            <tr>
                                <td class="leftTable">Brokerage Name:</td>
                                <td><input type="text" name="${record?.personId}_firmName" value="${record?.firmName || ''}"
                                        placeholder="Listing Company Name" disabled></td>
                            </tr>
                            <tr>
                                <td>Brokerage ID:</td>
                                <td><input type="text" name="${record?.personId}_firmLicense" value="${record?.firmLicense || ''}"
                                        placeholder="Seller Agent Office ID/License No." disabled></td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>
                                    <span class="grow-with-content">
                                        <textarea class="ta-like grow" name="${record?.personId}_address" placeholder="Address" disabled>${record?.address || ''}</textarea>
                                    </span>
                                <!-- <input type="text" name="${record?.personId}_address" placeholder="Address" value="${record?.address || ''}" disabled> -->
                                </td>
                            </tr>
                            <tr>
                                <td>Agent:</td>
                                <td><input type="text" name="${record?.personId}_fullName" value="${record?.fullName || ''}"
                                        placeholder="Seller Agent Name" disabled></td>
                            </tr>
                            <tr>
                                <td>Agent ID:</td>
                                <td><input type="text" name="${record?.personId}_license" value="${record?.license || ''}"
                                        placeholder="Seller Agent ID/License No." disabled></td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td><input class="formatPhoneNumber" type="text"
                                        name="${record?.personId}_phoneNumber" value="${record?.phoneNumber || ''}" placeholder="Seller Agent Phone No."
                                        disabled></td>
                            </tr>
                            <tr>
                                <td>Fax:</td>
                                <td><input class="formatPhoneNumber" type="text"
                                        name="${record?.personId}_faxNumber" value="${record?.faxNumber || ''}" placeholder="Seller Agent faxNumber No."
                                        disabled></td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td><input type="text" name="${record?.personId}_email" value="${record?.email || ''}"
                                        placeholder="Seller Agent Email" disabled></td>
                            </tr>
                        </table>
                    `
                }).join(`<div class="sectionTitle" style="margin: 5px 0;"></div>`)
            }
        }
    }
}