/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Avatar, Form, Modal, Table, Tooltip } from "antd";
import { PlusCircleFilled,CheckCircleOutlined ,CloseCircleOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import { CreateTitlecompany } from "./CreateTitlecompany";
import { firmDetailsHandle } from "../../FirmCreation/state/action";
import { useDispatch, useSelector } from "react-redux";
import { TitleCompanyHandle } from "../state/action";
import { Loading } from "../../Common";

const TitleCompanyCreation = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const {gettitleCompanyData, gettitleCompanyTotalRecords, gettitleCompanyLoading} = useSelector((state)=>state.titleCompanyDetails)

  const titleCompanyCreationColumns = [
    {
      title: 'CREATION TYPE',
      dataIndex: 'titleType',
      align: "center",
      key: 'titleType',
      render: (text) => (
        <div>
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: 'CREATED BY',
      dataIndex: 'nameemail',
      align: "center",
      key: 'nameemail',
      width: '16%',
      render: (text) => (
        <div>

          <div >
            <div>{text?.fullName}</div>
            <div style={{ fontSize: "11px", color: "#787474" }}>
              {text?.email ? text.email : ""}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      align: "center",
      key: 'status',
      width: '12%',
      render: (isVerified) => <div>
        {
          isVerified ? <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <CheckCircleOutlined style={{ color: "#4bd33a", fontSize: "18px" }} />
            Verified
          </div> : <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <CloseCircleOutlined style={{ color: "#e73939", fontSize: "18px" }} />
            Not Verified
          </div>
        }
      </div>,
    },
    {
      title: 'TITLE COMPANY',
      dataIndex: 'company',
      align: "center",
      key: 'company',
      width: '23%',
      render: (text) => (
        <div style={{ display: "flex" }}>
          <div style={{ paddingTop: "10px" }}>{text?.officeLogo ? <Avatar shape="circle" size={32} src={text?.officeLogo} /> : ""}</div>
          <div style={{ marginLeft: "10px" }}>
            <div style={{ fontSize: "13px", color: "black" }}>{text?.name}</div>
            <div style={{ fontSize: "11px", color: "#787474" }}>
              {text?.address ? `Address: ${text?.address}` : ""}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'TITLE OFFICE',
      dataIndex: 'office',
      key: 'office',
      align: "center",
      width: '23%',
      render: (text) => (
        <div>
          <div style={{ fontSize: "13px", color: "black" }}>{text?.name}</div>
          <div style={{ fontSize: "11px", color: "#787474" }}>
            {text?.address ? `Address: ${text?.address}` : ""}
          </div>
        </div>
      ),
    },
    {
      title: "UPDATE",
      dataIndex: "update", 
      align: "center",
      key: "update",
      render: (ele, allRecord) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            style={{ 
              cursor: "pointer", 
              color: ele?.isVerified ? "red" : "black",
              border: "none",
              padding: "5px 10px",
              borderRadius: "5px"
            }}
            onClick={() => handleConfirmVerification(ele, allRecord)}
          >
            {ele?.isVerified ? "Mark Unverified" : "Mark Verified"}
          </button>
        </div>
      ),
    }
  ];
  const handleConfirmVerification = (ele, record) => {
    const companyName = ele?.titleOfficeId?.length === 24 ? record?.office?.name || "this title company" : ele?.titleCompanyId?.length === 24 ? record?.company?.name || "this title company" : "this title company";
    Modal.confirm({
      title: !ele?.isVerified ? `Are you sure you want to verify ${companyName}?` : `Are you sure you want to unverify ${companyName}?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleVerification(ele?.titleCompanyId, ele?.titleOfficeId, ele?.isVerified);
      },
      onCancel() {},
    });
  };
  const handleVerification = (titleCompanyId, titleOfficeId, isVerified) => {
    let data = {
      titleOfficeId: titleOfficeId?.length === 24 ? titleOfficeId : titleCompanyId?.length === 24 ? titleCompanyId : null,
      isVerified: !isVerified,
      currentPage,
      pageSize: 20,
    };

    dispatch(TitleCompanyHandle.updateClosuresSalesRep(data));
  };

  const handleCancelModal = () => {
    form.resetFields();
    setShowCreateModal(false);
    dispatch(firmDetailsHandle.resetImageUrl());
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  useEffect(() => {
    dispatch(TitleCompanyHandle.getTitleCompanyTypes({titleCompanyMenu: "createTitleCompany", currentPage, pageSize: 20 }));
  }, [currentPage]);

return (
    <div>
      <Modal
        visible={showCreateModal}
        style={{ top: 20,  minWidth: "620px", maxWidth: "1000px"  }}
        footer={false}
        onCancel={handleCancelModal}
        onOk={() => {}}
      >
        <CreateTitlecompany setShowCreateModal={setShowCreateModal} form={form} />
      </Modal>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "5px 30px 10px 10px",
          }}
        >
          <div>
            <h1>Lists of Created Title Company</h1>
          </div>
          <Tooltip title={"Create Title Company"} placement="left" color={"#085191"}>
            <PlusCircleFilled
              style={{
                fontSize: "30px",
                fill: "#085191",
                color: "#085191",
              }}
              onClick={() => {
                setShowCreateModal(true);
              }}
            />
          </Tooltip>
        </div>
      {gettitleCompanyLoading ? <Loading /> :
        <Table
          columns={titleCompanyCreationColumns}
          scroll={{ y: "60vh" }}
          dataSource={gettitleCompanyData}
          bordered
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            pageSize: 20,
            total: gettitleCompanyTotalRecords,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
        />}
        <div>
        </div>
      </>
    </div>
  );
};

export { TitleCompanyCreation };
